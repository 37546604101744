var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("sys-pop-over-config-base", {
        attrs: { model: _vm.model, configs: _vm.configs },
        scopedSlots: _vm._u([
          {
            key: "custom",
            fn: function() {
              return [
                _c("sys-select-term", {
                  attrs: {
                    model: _vm.model.term_id,
                    error: _vm.errors.length > 0,
                    errors: _vm.errors.term_id,
                    label: "Termo",
                    showClear: ""
                  },
                  on: {
                    "update:model": function($event) {
                      return _vm.$set(_vm.model, "term_id", $event)
                    }
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }