var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.model,
      title: _vm.title,
      icon: _vm.icon,
      show: _vm.dialog,
      maxWidth: "20%",
      "show-button": _vm.type == "edit"
    },
    on: {
      onSave: _vm.save,
      "update:model": function($event) {
        _vm.model = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u(
      [
        {
          key: "form",
          fn: function() {
            return [
              _vm.model
                ? _c(
                    "v-container",
                    [
                      _vm.newItem
                        ? _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "12" } },
                                [
                                  _c("sys-select-teacher", {
                                    attrs: {
                                      model: _vm.model2.teacher_id,
                                      error:
                                        _vm.errors.teacher_id &&
                                        _vm.errors.length > 0,
                                      data: _vm.model2.teacher,
                                      errors: _vm.errors.teacher_id,
                                      label: "Professor*",
                                      autoComplete: "",
                                      autofocus: "",
                                      showClear: ""
                                    },
                                    on: {
                                      "update:model": function($event) {
                                        return _vm.$set(
                                          _vm.model2,
                                          "teacher_id",
                                          $event
                                        )
                                      },
                                      "update:data": function($event) {
                                        return _vm.$set(
                                          _vm.model2,
                                          "teacher",
                                          $event
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "12" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  error: !!_vm.errors.work_load,
                                  "error-messages": _vm.errors.work_load,
                                  label: "Carga Horária*",
                                  disabled: _vm.type != "edit"
                                },
                                model: {
                                  value: _vm.model2.work_load,
                                  callback: function($$v) {
                                    _vm.$set(_vm.model2, "work_load", $$v)
                                  },
                                  expression: "model2.work_load"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "12" } },
                            [
                              _c("emc-calendar-date-picker2", {
                                attrs: {
                                  model: _vm.model2.date_start,
                                  label: "Data Início*",
                                  errorMessages: _vm.errors.date_start,
                                  show: _vm.dialog,
                                  min: _vm.acesso.periodo.start,
                                  max: _vm.acesso.periodo.end,
                                  disabled: _vm.type != "edit"
                                },
                                on: {
                                  "update:model": function($event) {
                                    return _vm.$set(
                                      _vm.model2,
                                      "date_start",
                                      $event
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "12" } },
                            [
                              _c("emc-calendar-date-picker2", {
                                attrs: {
                                  model: _vm.model2.date_end,
                                  label: "Data Fim*",
                                  errorMessages: _vm.errors.date_end,
                                  show: _vm.dialog,
                                  min: _vm.acesso.periodo.start,
                                  max: _vm.acesso.periodo.end,
                                  disabled: _vm.type == "remove"
                                },
                                on: {
                                  "update:model": function($event) {
                                    return _vm.$set(
                                      _vm.model2,
                                      "date_end",
                                      $event
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c("emc-alert-snackbar", {
                attrs: {
                  show: _vm.errors.length > 0,
                  errors: _vm.errors,
                  color: _vm.error ? "error" : "success"
                }
              }),
              _c("emc-alert-modal", {
                attrs: {
                  text:
                    "Essa ação vai gerar um desconto de pagamento. Deseja continuar?",
                  show: _vm.showRemove
                },
                on: {
                  "update:show": function($event) {
                    _vm.showRemove = $event
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "actions",
                    fn: function() {
                      return [
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "error", loading: _vm.loading },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                $event.stopPropagation()
                                return _vm.remove()
                              }
                            }
                          },
                          [
                            _vm._v("Remover "),
                            _c("v-icon", { attrs: { right: "", dark: "" } }, [
                              _vm._v("mdi-account-remove")
                            ])
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                ])
              }),
              _c("emc-alert-modal", {
                attrs: {
                  text:
                    "Essa ação irá gerar um desconto de pagamento caso a data fim seja retroativa. Deseja continuar?",
                  show: _vm.showFinalize
                },
                on: {
                  "update:show": function($event) {
                    _vm.showFinalize = $event
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "actions",
                    fn: function() {
                      return [
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "purple", loading: _vm.loading },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                $event.stopPropagation()
                                return _vm.finalize()
                              }
                            }
                          },
                          [
                            _vm._v("Finalizar "),
                            _c("v-icon", { attrs: { right: "", dark: "" } }, [
                              _vm._v("mdi-account-convert")
                            ])
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ]
          },
          proxy: true
        },
        _vm.model && _vm.model.exported
          ? {
              key: "actions",
              fn: function() {
                return [
                  _vm.type == "remove"
                    ? _c(
                        "v-btn",
                        {
                          attrs: { color: "error" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              $event.stopPropagation()
                              _vm.showRemove = true
                            }
                          }
                        },
                        [
                          _vm._v(" Remover"),
                          _c("v-icon", { attrs: { right: "", dark: "" } }, [
                            _vm._v("mdi-account-remove")
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.type == "finalize"
                    ? _c(
                        "v-btn",
                        {
                          attrs: { color: "purple" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              $event.stopPropagation()
                              _vm.showFinalize = true
                            }
                          }
                        },
                        [
                          _vm._v(" Finalizar"),
                          _c("v-icon", { attrs: { right: "", dark: "" } }, [
                            _vm._v("mdi-account-convert")
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ]
              },
              proxy: true
            }
          : null
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }