var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("sys-select-base", {
        class: _vm.classe,
        attrs: {
          model: _vm.selected,
          items: _vm.items,
          loading: _vm.loading,
          label: _vm.label,
          error: _vm.error,
          errors: _vm.errors,
          showClear: _vm.showClear,
          multiple: _vm.multiple,
          dense: _vm.dense,
          disabled: _vm.disabled,
          outlined: _vm.outlined,
          hideDetails: _vm.hideDetails,
          classe: _vm.classe,
          "background-color": _vm.backgroundColor,
          color: _vm.color
        },
        on: {
          "update:model": function($event) {
            _vm.selected = $event
          },
          change: function($event) {
            return _vm.$emit("change", $event)
          },
          input: function($event) {
            return _vm.$emit("input", $event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }