var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mt-3" }, [
    _c(
      "div",
      { attrs: { id: "extrato-teacher" } },
      [
        _c("div", [
          _c("strong", [_vm._v("Professor:")]),
          _vm._v(" " + _vm._s(_vm.teacher.name))
        ]),
        _vm.teacher.titling
          ? _c("div", [
              _c("strong", [_vm._v("Titulação:")]),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.teacher.titling.name || ""))])
            ])
          : _vm._e(),
        _vm.teacher.workRegime
          ? _c("div", [
              _c("strong", [_vm._v("Regime de Trabalho:")]),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.teacher.workRegime.name || ""))])
            ])
          : _vm._e(),
        !_vm.grid
          ? _c("emc-base-card", {
              staticClass: "mt-3",
              attrs: {
                "class-toolbar": "elevation-1",
                "class-title": "body-1",
                title: "Disponibilidade",
                icon: "mdi-account-clock-outline"
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "card-text",
                    fn: function() {
                      return [
                        _c("SysTeacherAvailabilitySummary", {
                          attrs: { teacher: _vm.teacher }
                        })
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                648311880
              )
            })
          : _vm._e(),
        _vm.grid
          ? _c("emc-base-card", {
              staticClass: "mt-3",
              attrs: {
                "class-toolbar": "elevation-1",
                "class-title": "body-1",
                title: "Horários da Grade",
                icon: "mdi-timetable"
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "card-text",
                    fn: function() {
                      return [
                        _c("SysTeacherConfirmedTimes", {
                          attrs: {
                            "hide-joins": !!_vm.grid,
                            workTime: _vm.workTime,
                            grid: _vm.grid
                          }
                        })
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                2254049892
              )
            })
          : _vm._e(),
        _c("emc-base-card", {
          staticClass: "mt-3",
          attrs: {
            "class-toolbar": "elevation-1",
            "class-title": "body-1",
            title: "Horários do Professor",
            icon: "mdi-timetable"
          },
          scopedSlots: _vm._u([
            {
              key: "card-text",
              fn: function() {
                return [
                  _c("SysTeacherConfirmedTimes", {
                    attrs: {
                      "hide-joins": !!_vm.grid,
                      workTime: _vm.workTime,
                      teacher: _vm.teacher
                    }
                  })
                ]
              },
              proxy: true
            }
          ])
        }),
        !_vm.workTime.show_attributions
          ? _c("emc-base-card", {
              staticClass: "mt-3",
              attrs: {
                "class-toolbar": "elevation-1",
                "class-title": "body-1",
                title: "Indicações",
                icon: "school"
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "card-text",
                    fn: function() {
                      return [
                        _c("SysTeacherIndication", {
                          attrs: {
                            "hide-joins": !!_vm.grid,
                            teacher: _vm.teacher
                          }
                        })
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                3872989011
              )
            })
          : _c("emc-base-card", {
              staticClass: "mt-3",
              attrs: {
                "class-toolbar": "elevation-1",
                "class-title": "body-1",
                title: "Atribuições",
                icon: "school"
              },
              scopedSlots: _vm._u([
                {
                  key: "card-text",
                  fn: function() {
                    return [
                      _c("SysTeacherAttributionTable", {
                        attrs: { workTime: _vm.workTime, teacher: _vm.teacher }
                      })
                    ]
                  },
                  proxy: true
                }
              ])
            }),
        _c("emc-base-card", {
          staticClass: "mt-3",
          attrs: {
            "class-toolbar": "elevation-1",
            "class-title": "body-1",
            title: "Atividades",
            icon: "school"
          },
          scopedSlots: _vm._u([
            {
              key: "card-text",
              fn: function() {
                return [
                  _c("SysTeacherOtherActivities", {
                    attrs: { teacher: _vm.teacher }
                  })
                ]
              },
              proxy: true
            }
          ])
        }),
        _c("emc-base-card", {
          staticClass: "mt-3",
          attrs: {
            "class-toolbar": "elevation-1",
            "class-title": "body-1",
            title: "Horário das Atividades",
            icon: "school"
          },
          scopedSlots: _vm._u([
            {
              key: "card-text",
              fn: function() {
                return [
                  _c("SysTeacherOtherActivityTimeTable", {
                    attrs: { teacher: _vm.teacher }
                  })
                ]
              },
              proxy: true
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }