var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isLoading ? _c("EmcBaseProgressBar") : _vm._e(),
      _c("v-treeview", {
        ref: "tree",
        attrs: {
          active: _vm.active,
          items: _vm.campuses,
          "load-children": _vm.fetchItems,
          open: _vm.open,
          activatable: "",
          "item-children": "children",
          color: "warning",
          "open-on-click": true,
          transition: "",
          "item-key": "key",
          "return-object": ""
        },
        on: {
          "update:active": [
            function($event) {
              _vm.active = $event
            },
            function($event) {
              return _vm.selectItem($event)
            }
          ],
          "update:open": function($event) {
            _vm.open = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "label",
            fn: function(ref) {
              var item = ref.item
              var active = ref.active
              return [_c("span", [_vm._v(_vm._s(item.name))])]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }