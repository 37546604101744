var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width":
          _vm.$vuetify.breakpoint.name == "xs" ||
          _vm.$vuetify.breakpoint.name == "sm"
            ? "90%"
            : _vm.maxWidth,
        persistent: ""
      },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "ma-0 pa-0" },
            [
              _c(
                "v-app-bar",
                {
                  class: _vm.color + " mb-4 elevation-1",
                  attrs: { dense: "", height: "45" }
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v(_vm._s(_vm.icon))
                  ]),
                  _c(
                    "v-toolbar-title",
                    { staticClass: "subtitle-1" },
                    [_vm._t("title", [_vm._v(_vm._s(_vm.title))])],
                    2
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: { icon: "" },
                                      nativeOn: {
                                        click: function($event) {
                                          _vm.dialog = false
                                        }
                                      }
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "red lighten-2" } },
                                    [_vm._v("mdi-close")]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [_c("span", [_vm._v("Fechar")])]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-container",
                [
                  _vm.reportId == 100
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "4" } },
                            [
                              _c("emc-calendar-date-picker2", {
                                staticClass: "mt-2",
                                attrs: {
                                  model: _vm.databricks.date,
                                  label: "Data Foto*",
                                  show: _vm.dialog
                                },
                                on: {
                                  "update:model": function($event) {
                                    return _vm.$set(
                                      _vm.databricks,
                                      "date",
                                      $event
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.reportId == 100
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "12" } },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  rows: "3",
                                  clearable: "",
                                  label: "Filtro",
                                  outlined: "",
                                  placeholder:
                                    "Exemplo: cod_turma_composicao = 123 and cod_disciplina = 456",
                                  "persistent-placeholder": ""
                                },
                                model: {
                                  value: _vm.databricks.filter,
                                  callback: function($$v) {
                                    _vm.$set(_vm.databricks, "filter", $$v)
                                  },
                                  expression: "databricks.filter"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "12" } },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  rows: "3",
                                  clearable: "",
                                  label: "Colunas (separadas por ',')",
                                  outlined: "",
                                  placeholder:
                                    "Exemplo: cod_turma_composicao, cod_disciplina, ...",
                                  "persistent-placeholder": ""
                                },
                                model: {
                                  value: _vm.databricks.columns,
                                  callback: function($$v) {
                                    _vm.$set(_vm.databricks, "columns", $$v)
                                  },
                                  expression: "databricks.columns"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.reportId == 53 || _vm.reportId == 79
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "12" } },
                            [
                              _c("sys-select-brand", {
                                attrs: {
                                  model: _vm.brands,
                                  label: "Marca",
                                  showClear: "",
                                  multiple: "",
                                  dense: ""
                                },
                                on: {
                                  "update:model": function($event) {
                                    _vm.brands = $event
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.reportId == 66 || _vm.reportId == 32
                    ? _c(
                        "v-row",
                        [
                          _vm.showFilterPaymentExport
                            ? _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "4" } },
                                [
                                  _c("sys-select-month", {
                                    attrs: { dense: "", model: _vm.month },
                                    on: {
                                      "update:model": function($event) {
                                        _vm.month = $event
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.showFilterPaymentExport
                            ? _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "8" } },
                                [
                                  _c("sys-select-payment-type", {
                                    attrs: {
                                      model: _vm.paymentTypes,
                                      label: "Tipo de Pagamento*",
                                      multiple: "",
                                      dense: ""
                                    },
                                    on: {
                                      "update:model": function($event) {
                                        _vm.paymentTypes = $event
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "12" } },
                            [
                              _c("sys-select-company", {
                                attrs: {
                                  model: _vm.companies,
                                  label: "Empresa (Obrigatório)",
                                  multiple: "",
                                  dense: "",
                                  "select-all": ""
                                },
                                on: {
                                  "update:model": function($event) {
                                    _vm.companies = $event
                                  }
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "12" } },
                            [
                              _c("sys-select-branch", {
                                attrs: {
                                  model: _vm.branches,
                                  label: "Filial (Opcional)",
                                  multiple: "",
                                  dense: "",
                                  disabled: _vm.companies.length == 0,
                                  "company-id": _vm.companies
                                    .map(function(i) {
                                      return i.id
                                    })
                                    .join(",")
                                },
                                on: {
                                  "update:model": function($event) {
                                    _vm.branches = $event
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "ligth green",
                    loading: _vm.loading,
                    disabled: _vm.disabled
                  },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      $event.stopPropagation()
                      return _vm.downalodItem()
                    }
                  }
                },
                [
                  _vm._v("Download"),
                  _c("v-icon", { attrs: { right: "", dark: "" } }, [
                    _vm._v("mdi-file-download-outline")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }