var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width":
          _vm.$vuetify.breakpoint.name == "xs" ||
          _vm.$vuetify.breakpoint.name == "sm"
            ? "80%"
            : _vm.maxWidth,
        persistent: ""
      },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _vm.renderComponent
        ? _c("emc-loader-progress", { attrs: { show: _vm.progress } })
        : _vm._e(),
      _c(
        "v-card",
        { staticClass: "mx-auto elevation-0", attrs: { height: "80hv" } },
        [
          _c(
            "v-card-title",
            { staticClass: "grey lighten-5", attrs: { dark: "" } },
            [
              _c("v-icon", { attrs: { left: "" } }),
              _vm._v(" Impressão de Folha de Ponto ")
            ],
            1
          ),
          _c(
            "v-container",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-card",
                        {
                          staticClass:
                            "mx-auto elevation-0 overflow-y-auto overflow-x-auto",
                          attrs: { outlined: "", height: "60vh", prominent: "" }
                        },
                        [
                          _c(
                            "v-list",
                            { attrs: { flat: "" } },
                            [
                              _c(
                                "v-list-item-group",
                                {
                                  attrs: { color: "primary" },
                                  model: {
                                    value: _vm.selectedCampus,
                                    callback: function($$v) {
                                      _vm.selectedCampus = $$v
                                    },
                                    expression: "selectedCampus"
                                  }
                                },
                                _vm._l(_vm.campuses, function(item) {
                                  return _c(
                                    "v-list-item",
                                    { key: item.id },
                                    [
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-title", {
                                            domProps: {
                                              textContent: _vm._s(item.name)
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-card",
                        {
                          staticClass:
                            "mx-auto elevation-0 overflow-y-auto overflow-x-auto",
                          attrs: { outlined: "", height: "60vh", prominent: "" }
                        },
                        [
                          _c(
                            "v-app-bar",
                            {
                              attrs: {
                                prominent: "",
                                flat: "",
                                color: "transparent",
                                height: "40px"
                              }
                            },
                            [
                              _c("v-text-field", {
                                staticClass: "pa-0",
                                attrs: {
                                  "prepend-inner-icon": "search",
                                  "x-small": "",
                                  placeholder: "Pesquisar",
                                  rounded: "",
                                  "single-line": "",
                                  clearable: ""
                                },
                                model: {
                                  value: _vm.searchTeacher,
                                  callback: function($$v) {
                                    _vm.searchTeacher = $$v
                                  },
                                  expression: "searchTeacher"
                                }
                              }),
                              _c("v-checkbox", {
                                attrs: { label: "Todos" },
                                model: {
                                  value: _vm.checkboxAllTeacher,
                                  callback: function($$v) {
                                    _vm.checkboxAllTeacher = $$v
                                  },
                                  expression: "checkboxAllTeacher"
                                }
                              })
                            ],
                            1
                          ),
                          _c("v-treeview", {
                            ref: "treeTeachers",
                            staticClass: "pa-0",
                            attrs: {
                              items: _vm.itemsTeachers,
                              "item-key": "id",
                              "item-name": "name",
                              "return-object": "",
                              "item-children": "children",
                              selectable: "",
                              "open-all": false,
                              search: _vm.searchTeacher
                            },
                            model: {
                              value: _vm.modelTeachers,
                              callback: function($$v) {
                                _vm.modelTeachers = $$v
                              },
                              expression: "modelTeachers"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-list",
                        [
                          _c(
                            "v-list-item",
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.months,
                                  "item-value": "id",
                                  "item-text": "name",
                                  label: "Mês",
                                  outlined: ""
                                },
                                model: {
                                  value: _vm.month,
                                  callback: function($$v) {
                                    _vm.month = $$v
                                  },
                                  expression: "month"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-action",
                                [
                                  _c("v-switch", {
                                    attrs: { color: "purple" },
                                    model: {
                                      value: _vm.params.showEntry,
                                      callback: function($$v) {
                                        _vm.$set(_vm.params, "showEntry", $$v)
                                      },
                                      expression: "params.showEntry"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c("v-list-item-title", [
                                _vm._v("Exibir Assinatura de Entrada/Saída")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-action",
                                [
                                  _c("v-switch", {
                                    attrs: { color: "purple" },
                                    model: {
                                      value: _vm.params.showHoliday,
                                      callback: function($$v) {
                                        _vm.$set(_vm.params, "showHoliday", $$v)
                                      },
                                      expression: "params.showHoliday"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c("v-list-item-title", [
                                _vm._v("Exibir datas não letivas")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-action",
                                [
                                  _c("v-switch", {
                                    attrs: { color: "purple" },
                                    on: {
                                      change: function($event) {
                                        return _vm.changeState()
                                      }
                                    },
                                    model: {
                                      value: _vm.params.groupByShift,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.params,
                                          "groupByShift",
                                          $$v
                                        )
                                      },
                                      expression: "params.groupByShift"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c("v-list-item-title", [
                                _vm._v("Agrupar por Turno")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-action",
                                [
                                  _c("v-switch", {
                                    attrs: {
                                      disabled: _vm.params.groupByShift,
                                      color: "purple"
                                    },
                                    model: {
                                      value: _vm.params.showDiscipline,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.params,
                                          "showDiscipline",
                                          $$v
                                        )
                                      },
                                      expression: "params.showDiscipline"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c("v-list-item-title", [
                                _vm._v("Exibir Disciplina")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-action",
                                [
                                  _c("v-switch", {
                                    attrs: { color: "purple" },
                                    model: {
                                      value: _vm.params.showClasses,
                                      callback: function($$v) {
                                        _vm.$set(_vm.params, "showClasses", $$v)
                                      },
                                      expression: "params.showClasses"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c("v-list-item-title", [
                                _vm._v("Exibir Número de Aulas")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            [
                              _c(
                                "v-radio-group",
                                {
                                  attrs: { column: "" },
                                  model: {
                                    value: _vm.type,
                                    callback: function($$v) {
                                      _vm.type = $$v
                                    },
                                    expression: "type"
                                  }
                                },
                                [
                                  _c("v-radio", {
                                    attrs: {
                                      label: "PDF",
                                      color: "red",
                                      value: "pdf"
                                    }
                                  }),
                                  _c("v-radio", {
                                    attrs: {
                                      label: "Excel",
                                      color: "green darken-3",
                                      value: "excel"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c("v-spacer"),
                              _c(
                                "v-radio-group",
                                {
                                  attrs: { column: "" },
                                  model: {
                                    value: _vm.params.orientation,
                                    callback: function($$v) {
                                      _vm.$set(_vm.params, "orientation", $$v)
                                    },
                                    expression: "params.orientation"
                                  }
                                },
                                [
                                  _c("v-radio", {
                                    attrs: {
                                      label: "Retrato",
                                      color: "purple",
                                      value: "portrait"
                                    }
                                  }),
                                  _c("v-radio", {
                                    attrs: {
                                      label: "Paisagem",
                                      color: "blue",
                                      value: "landscape"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: _vm.type == "excel" ? "success" : "error",
                    disabled: _vm.modelTeachers.length == 0
                  },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      $event.stopPropagation()
                      return _vm.printTimeSheet()
                    }
                  }
                },
                [
                  _vm._v("Imprimir"),
                  _c("v-icon", { attrs: { right: "", dark: "" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.type == "excel"
                          ? "mdi-microsoft-excel"
                          : "mdi-file-pdf-box-outline"
                      )
                    )
                  ])
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  nativeOn: {
                    click: function($event) {
                      _vm.dialog = false
                    }
                  }
                },
                [_vm._v("Fechar")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }