var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-center ml-2" },
    [
      _c(
        "v-menu",
        {
          attrs: {
            "close-on-content-click": false,
            "open-on-hover": _vm.openOnHover,
            "close-delay": _vm.delay,
            "offset-y": "",
            bottom: "",
            "max-width":
              _vm.$vuetify.breakpoint.name == "xs" ||
              _vm.$vuetify.breakpoint.name == "sm"
                ? "90%"
                : "90%"
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: { icon: "", color: "grey", small: !_vm.small }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _vm.openOnHover
                        ? _c(
                            "v-icon",
                            _vm._g(
                              _vm._b(
                                { attrs: { color: _vm.color } },
                                "v-icon",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_vm._v(_vm._s(_vm.icon))]
                          )
                        : _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-icon",
                                          _vm._g(
                                            _vm._b(
                                              { attrs: { color: _vm.color } },
                                              "v-icon",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [_vm._v(_vm._s(_vm.icon))]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [_c("span", [_vm._v(_vm._s(_vm.title))])]
                          )
                    ],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.menu,
            callback: function($$v) {
              _vm.menu = $$v
            },
            expression: "menu"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "ma-0 pa-0" },
                [
                  _c(
                    "v-app-bar",
                    {
                      staticClass: "grey lighten-5 mb-4 elevation-1'",
                      attrs: { dense: "" }
                    },
                    [
                      _c("v-toolbar-title", { staticClass: "subtitle-1" }, [
                        _vm._v(" " + _vm._s(_vm.title) + " ")
                      ]),
                      _c("v-spacer"),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass: "mr-1",
                                          attrs: { icon: "", small: "" },
                                          nativeOn: {
                                            click: function($event) {
                                              _vm.menu = false
                                            }
                                          }
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "red lighten-2" } },
                                        [_vm._v("mdi-close")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [_c("span", [_vm._v("Fechar")])]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-list",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "emc-util-scroll",
                        { attrs: { maxHeight: "600px" } },
                        _vm._l(_vm.items, function(item, index) {
                          return _c(
                            "v-list-item",
                            { key: index },
                            [
                              _c(
                                "v-list-item-title",
                                [
                                  _c("s-icon-report-download-optimized", {
                                    attrs: {
                                      delay: _vm.delay,
                                      menu: _vm.menu,
                                      small: "",
                                      "tool-tip": "Download",
                                      icon: "mdi-download",
                                      report: item.id
                                    },
                                    on: {
                                      "update:delay": function($event) {
                                        _vm.delay = $event
                                      },
                                      "update:menu": function($event) {
                                        _vm.menu = $event
                                      },
                                      onCompleted: _vm.downloadCompleted
                                    }
                                  }),
                                  _vm._v(" " + _vm._s(item.name) + " ")
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }