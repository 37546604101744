var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { id: "inspire" } },
    [
      _vm.$route.path.includes("login") ||
      _vm.$route.path.includes("access-teacher") ||
      _vm.$route.path.includes("access-time-sheet")
        ? [
            _vm.$route.path.includes("login") ? _c("Login") : _vm._e(),
            _vm.$route.path.includes("access-teacher")
              ? _c("AccessTeacher")
              : _vm._e(),
            _vm.$route.path.includes("access-time-sheet")
              ? _c("AccessTimeSheet")
              : _vm._e()
          ]
        : _c("SysCoreMaster")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }