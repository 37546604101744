var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.model,
      title: _vm.title,
      icon: "mdi-school-outline",
      show: _vm.dialog,
      maxWidth: "90%"
    },
    on: {
      onSave: _vm.save,
      "update:model": function($event) {
        _vm.model = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "tools",
        fn: function() {
          return [
            _vm.acesso.instituicao &&
            _vm.acesso.instituicao.settings.enable_photo_teacher &&
            !_vm.onlyHiring &&
            _vm.model.id
              ? _c("emc-button-icon", {
                  attrs: {
                    text: "Cadastro de Fotos",
                    icon: "mdi-camera-image"
                  },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      $event.stopPropagation()
                      _vm.dialogImages = true
                    }
                  }
                })
              : _vm._e(),
            _vm.tab === 1 && !!_vm.model.id
              ? _c(
                  "v-btn",
                  { attrs: { icon: "" } },
                  [
                    _c(
                      "v-icon",
                      {
                        attrs: { color: "red lighten-1" },
                        on: {
                          click: function($event) {
                            return _vm.$refs.extract.createPDF()
                          }
                        }
                      },
                      [_vm._v("mdi-file-pdf-box")]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.tab === 2 && !!_vm.model.id
              ? _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: {
                                        color: "success darken-1",
                                        icon: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          $event.stopPropagation()
                                          _vm.showAddLicense = true
                                        }
                                      }
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [_c("v-icon", [_vm._v("mdi-plus")])],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2030204541
                    )
                  },
                  [_c("span", [_vm._v(" Cadastrar Nova Licença ")])]
                )
              : _vm._e(),
            _vm.tab === 3 && !!_vm.model.id
              ? _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: {
                                        color: "success darken-1",
                                        icon: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          $event.stopPropagation()
                                          _vm.showAddActivity = true
                                        }
                                      }
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [_c("v-icon", [_vm._v("mdi-plus")])],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3611010763
                    )
                  },
                  [_c("span", [_vm._v(" Cadastrar Nova Atividade ")])]
                )
              : _vm._e(),
            _vm.tab === 4 && !!_vm.model.id
              ? _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: {
                                        color: "success darken-1",
                                        icon: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          $event.stopPropagation()
                                          _vm.showAddContract = true
                                        }
                                      }
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [_c("v-icon", [_vm._v("mdi-plus")])],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3099774292
                    )
                  },
                  [_c("span", [_vm._v(" Cadastrar Novo Vínculo ")])]
                )
              : _vm._e()
          ]
        },
        proxy: true
      },
      {
        key: "form",
        fn: function() {
          return [
            [
              _c(
                "v-tabs",
                {
                  staticClass: "mb-4",
                  on: {
                    change: function($event) {
                      return _vm.forceRerender()
                    }
                  },
                  model: {
                    value: _vm.tab,
                    callback: function($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab"
                  }
                },
                [
                  _c("v-tab", { key: "dados" }, [_vm._v("Dados")]),
                  !_vm.onlyHiring
                    ? _c("v-tab", { key: "extrato" }, [_vm._v("Extrato")])
                    : _vm._e(),
                  !_vm.onlyHiring
                    ? _c("v-tab", { key: "licenca" }, [_vm._v("Licenças")])
                    : _vm._e(),
                  !_vm.onlyHiring
                    ? _c("v-tab", { key: "atividade" }, [_vm._v("Atividades")])
                    : _vm._e(),
                  !_vm.onlyHiring
                    ? _c("v-tab", { key: "vinculo" }, [_vm._v("Vínculos")])
                    : _vm._e(),
                  !_vm.onlyHiring
                    ? _c("v-tab", { key: "endereco" }, [_vm._v("Endereço")])
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.tab,
                    callback: function($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab"
                  }
                },
                [
                  _c(
                    "v-tab-item",
                    { key: "dados" },
                    [
                      _c(
                        "v-row",
                        { staticClass: "mt-3" },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "3" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  dense: "",
                                  error: _vm.errors.length > 0,
                                  "error-messages": _vm.errors.code,
                                  label: "Código",
                                  required: ""
                                },
                                model: {
                                  value: _vm.model.code,
                                  callback: function($$v) {
                                    _vm.$set(_vm.model, "code", $$v)
                                  },
                                  expression: "model.code"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "9" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  autofocus: "",
                                  dense: "",
                                  error: _vm.errors.length > 0,
                                  "error-messages": _vm.errors.name,
                                  label: "Nome*",
                                  required: ""
                                },
                                model: {
                                  value: _vm.model.name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.model, "name", $$v)
                                  },
                                  expression: "model.name"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "8", xs: "12" } },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "12" } },
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          staticClass: "elevation-1",
                                          attrs: { tile: "" }
                                        },
                                        [
                                          _c(
                                            "v-toolbar",
                                            {
                                              staticClass: "elevation-1",
                                              attrs: {
                                                color: "grey lighten-3",
                                                height: "40"
                                              }
                                            },
                                            [
                                              _c("v-toolbar-title", [
                                                _vm._v("Informações acadêmicas")
                                              ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-card-text",
                                            [
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "4"
                                                      }
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        directives: [
                                                          {
                                                            name:
                                                              "mask-decimal",
                                                            rawName:
                                                              "v-mask-decimal.br",
                                                            value: 2,
                                                            expression: "2",
                                                            modifiers: {
                                                              br: true
                                                            }
                                                          }
                                                        ],
                                                        attrs: {
                                                          dense: "",
                                                          "append-icon":
                                                            "mdi-cash",
                                                          error:
                                                            _vm.errors.length >
                                                            0,
                                                          "error-messages":
                                                            _vm.errors
                                                              .hour_value,
                                                          label: "Valor Hora",
                                                          maxlength: "6"
                                                        },
                                                        model: {
                                                          value: _vm.hour_value,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.hour_value = $$v
                                                          },
                                                          expression:
                                                            "hour_value"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "4"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "sys-select-teacher-titling",
                                                        {
                                                          attrs: {
                                                            model:
                                                              _vm.model
                                                                .teacher_titling_id,
                                                            error:
                                                              _vm.errors
                                                                .length > 0,
                                                            errors:
                                                              _vm.errors
                                                                .teacher_titling_id,
                                                            label: "Titulação",
                                                            showClear: "",
                                                            dense: ""
                                                          },
                                                          on: {
                                                            "update:model": function(
                                                              $event
                                                            ) {
                                                              return _vm.$set(
                                                                _vm.model,
                                                                "teacher_titling_id",
                                                                $event
                                                              )
                                                            }
                                                          }
                                                        }
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "4"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "sys-select-teacher-work-regime",
                                                        {
                                                          attrs: {
                                                            model:
                                                              _vm.model
                                                                .teacher_work_regime_id,
                                                            error:
                                                              _vm.errors
                                                                .length > 0,
                                                            errors:
                                                              _vm.errors
                                                                .teacher_work_regime_id,
                                                            label:
                                                              "Regime de Trabalho",
                                                            showClear: "",
                                                            dense: ""
                                                          },
                                                          on: {
                                                            "update:model": function(
                                                              $event
                                                            ) {
                                                              return _vm.$set(
                                                                _vm.model,
                                                                "teacher_work_regime_id",
                                                                $event
                                                              )
                                                            }
                                                          }
                                                        }
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "12" } },
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          staticClass: "elevation-1",
                                          attrs: { tile: "" }
                                        },
                                        [
                                          _c(
                                            "v-toolbar",
                                            {
                                              staticClass: "elevation-1",
                                              attrs: {
                                                color: "grey lighten-3",
                                                height: "40"
                                              }
                                            },
                                            [
                                              _c("v-toolbar-title", [
                                                _vm._v("Demais informações")
                                              ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-card-text",
                                            [
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "6"
                                                      }
                                                    },
                                                    [
                                                      _c("v-combobox", {
                                                        attrs: {
                                                          items:
                                                            _vm.itemsCampus,
                                                          label: "Campus",
                                                          "item-text":
                                                            "abbreviation",
                                                          multiple: "",
                                                          clearable: "",
                                                          dense: ""
                                                        },
                                                        scopedSlots: _vm._u([
                                                          {
                                                            key: "selection",
                                                            fn: function(ref) {
                                                              var item =
                                                                ref.item
                                                              var index =
                                                                ref.index
                                                              return [
                                                                index === 0
                                                                  ? _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          item.abbreviation
                                                                        )
                                                                      )
                                                                    ])
                                                                  : _vm._e(),
                                                                index === 1
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "grey--text caption ml-1"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " (+" +
                                                                            _vm._s(
                                                                              _vm
                                                                                .model
                                                                                .campuses
                                                                                .length -
                                                                                1
                                                                            ) +
                                                                            " outros) "
                                                                        )
                                                                      ]
                                                                    )
                                                                  : _vm._e()
                                                              ]
                                                            }
                                                          }
                                                        ]),
                                                        model: {
                                                          value:
                                                            _vm.model.campuses,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.model,
                                                              "campuses",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "model.campuses"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "3"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "emc-calendar-date-picker2",
                                                        {
                                                          attrs: {
                                                            label:
                                                              "Disponibilidade",
                                                            model:
                                                              _vm.model
                                                                .release_availability_until,
                                                            errorMessages:
                                                              _vm.errors
                                                                .release_availability_until,
                                                            show: _vm.dialog,
                                                            dense: "",
                                                            showClear: "",
                                                            textHelper:
                                                              "Data limite para liberaração individual da disponibilidade."
                                                          },
                                                          on: {
                                                            "update:model": function(
                                                              $event
                                                            ) {
                                                              return _vm.$set(
                                                                _vm.model,
                                                                "release_availability_until",
                                                                $event
                                                              )
                                                            }
                                                          }
                                                        }
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "3"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "emc-calendar-date-picker2",
                                                        {
                                                          attrs: {
                                                            label:
                                                              "Confirmação CH",
                                                            model:
                                                              _vm.model
                                                                .release_confirmation_until,
                                                            errorMessages:
                                                              _vm.errors
                                                                .release_confirmation_until,
                                                            show: _vm.dialog,
                                                            dense: "",
                                                            showClear: "",
                                                            textHelper:
                                                              "Data limite para liberaração individual da confirmação de carga horária."
                                                          },
                                                          on: {
                                                            "update:model": function(
                                                              $event
                                                            ) {
                                                              return _vm.$set(
                                                                _vm.model,
                                                                "release_confirmation_until",
                                                                $event
                                                              )
                                                            }
                                                          }
                                                        }
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "4" } },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "elevation-1",
                                  attrs: { tile: "" }
                                },
                                [
                                  _c(
                                    "v-toolbar",
                                    {
                                      staticClass: "elevation-1",
                                      attrs: {
                                        color: "grey lighten-3",
                                        height: "40"
                                      }
                                    },
                                    [
                                      _c("v-toolbar-title", [
                                        _vm._v("Informações pessoais")
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-card-text",
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", sm: "6" } },
                                            [
                                              _c("v-text-field", {
                                                directives: [
                                                  {
                                                    name: "mask-phone",
                                                    rawName: "v-mask-phone.br",
                                                    modifiers: { br: true }
                                                  }
                                                ],
                                                attrs: {
                                                  dense: "",
                                                  "append-icon": "mdi-phone",
                                                  error: _vm.errors.length > 0,
                                                  "error-messages":
                                                    _vm.errors.mobile_phone,
                                                  label: "Celular 1"
                                                },
                                                model: {
                                                  value: _vm.model.mobile_phone,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.model,
                                                      "mobile_phone",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "model.mobile_phone"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", sm: "6" } },
                                            [
                                              _c("v-text-field", {
                                                directives: [
                                                  {
                                                    name: "mask-phone",
                                                    rawName: "v-mask-phone.br",
                                                    modifiers: { br: true }
                                                  }
                                                ],
                                                attrs: {
                                                  dense: "",
                                                  "append-icon": "mdi-phone",
                                                  error: _vm.errors.length > 0,
                                                  "error-messages":
                                                    _vm.errors.mobile_phone2,
                                                  label: "Celular 2"
                                                },
                                                model: {
                                                  value:
                                                    _vm.model.mobile_phone2,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.model,
                                                      "mobile_phone2",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "model.mobile_phone2"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", sm: "6" } },
                                            [
                                              _c("v-text-field", {
                                                directives: [
                                                  {
                                                    name: "mask-cpf",
                                                    rawName: "v-mask-cpf"
                                                  }
                                                ],
                                                attrs: {
                                                  dense: "",
                                                  "append-icon":
                                                    "mdi-card-account-details-outline",
                                                  maxlength: "14",
                                                  error: _vm.errors.length > 0,
                                                  "error-messages":
                                                    _vm.errors.number_cpf,
                                                  label: "CPF"
                                                },
                                                model: {
                                                  value: _vm.model.number_cpf,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.model,
                                                      "number_cpf",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "model.number_cpf"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", sm: "6" } },
                                            [
                                              _c("emc-calendar-date-picker2", {
                                                attrs: {
                                                  label: "Data Nascimento",
                                                  model: _vm.model.birth_date,
                                                  errorMessages:
                                                    _vm.errors.birth_date,
                                                  show: _vm.dialog,
                                                  readonly: false,
                                                  openOnClick: false,
                                                  showClear: "",
                                                  dense: ""
                                                },
                                                on: {
                                                  "update:model": function(
                                                    $event
                                                  ) {
                                                    return _vm.$set(
                                                      _vm.model,
                                                      "birth_date",
                                                      $event
                                                    )
                                                  }
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-card",
                                { staticClass: "mx-auto" },
                                [
                                  _c(
                                    "v-toolbar",
                                    {
                                      attrs: {
                                        color: "grey lighten-2",
                                        height: "40"
                                      }
                                    },
                                    [_c("v-toolbar-title", [_vm._v("Emails")])],
                                    1
                                  ),
                                  _c(
                                    "v-card-text",
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          type: "email",
                                          placeholder: "Digite o email",
                                          error: _vm.errors.length > 0,
                                          "error-messages": _vm.errors.emails
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "append-outer",
                                            fn: function() {
                                              return [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      color:
                                                        "green white--text",
                                                      fab: "",
                                                      "x-small": "",
                                                      loading:
                                                        _vm.loadingAddMail,
                                                      disabled:
                                                        _vm.loadingAddMail
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.addMail()
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("mdi-plus")
                                                    ])
                                                  ],
                                                  1
                                                )
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ]),
                                        model: {
                                          value: _vm.mail,
                                          callback: function($$v) {
                                            _vm.mail = $$v
                                          },
                                          expression: "mail"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-card-text",
                                    [
                                      _vm.renderComponent
                                        ? _c(
                                            "v-list",
                                            _vm._l(_vm.model.emails, function(
                                              email,
                                              i
                                            ) {
                                              return _c(
                                                "v-list-item",
                                                { key: i },
                                                [
                                                  _c(
                                                    "v-list-item-content",
                                                    [
                                                      _c("v-list-item-title", {
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            email.email
                                                          )
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { bottom: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var on = ref.on
                                                              var attrs =
                                                                ref.attrs
                                                              return [
                                                                _c(
                                                                  "span",
                                                                  _vm._g(
                                                                    _vm._b(
                                                                      {},
                                                                      "span",
                                                                      attrs,
                                                                      false
                                                                    ),
                                                                    on
                                                                  ),
                                                                  [
                                                                    _c(
                                                                      "v-switch",
                                                                      {
                                                                        attrs: {
                                                                          inset:
                                                                            ""
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            email.allow_editing,
                                                                          callback: function(
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              email,
                                                                              "allow_editing",
                                                                              $$v
                                                                            )
                                                                          },
                                                                          expression:
                                                                            "email.allow_editing"
                                                                        }
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          "Permitir edição pelo professor na sua página"
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-list-item-icon",
                                                    [
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { bottom: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  var attrs =
                                                                    ref.attrs
                                                                  return [
                                                                    _c(
                                                                      "v-icon",
                                                                      _vm._g(
                                                                        _vm._b(
                                                                          {
                                                                            staticClass:
                                                                              "mr-2",
                                                                            attrs: {
                                                                              color: email.is_validated
                                                                                ? "green"
                                                                                : "",
                                                                              small:
                                                                                ""
                                                                            }
                                                                          },
                                                                          "v-icon",
                                                                          attrs,
                                                                          false
                                                                        ),
                                                                        on
                                                                      ),
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-check-all"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                email.is_validated
                                                                  ? "Email validado!"
                                                                  : "Aguardando validação!"
                                                              )
                                                            )
                                                          ])
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { bottom: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  var attrs =
                                                                    ref.attrs
                                                                  return [
                                                                    _c(
                                                                      "v-btn",
                                                                      _vm._g(
                                                                        _vm._b(
                                                                          {
                                                                            attrs: {
                                                                              color:
                                                                                "red",
                                                                              icon:
                                                                                "",
                                                                              "x-small":
                                                                                "",
                                                                              loading:
                                                                                _vm.loadingRemoveMail &&
                                                                                _vm.mailDeleteId ==
                                                                                  i,
                                                                              disabled:
                                                                                _vm.loadingRemoveMail &&
                                                                                _vm.mailDeleteId ==
                                                                                  i
                                                                            },
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.removeMail(
                                                                                  email,
                                                                                  i
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          "v-btn",
                                                                          attrs,
                                                                          false
                                                                        ),
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          [
                                                                            _vm._v(
                                                                              "mdi-delete"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              "Excluir email?"
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            }),
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "12" } },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  error: !!_vm.errors.note,
                                  "error-messages": _vm.errors.note,
                                  label: "Observação",
                                  counter: 255,
                                  rows: "2",
                                  rules: [
                                    function(v) {
                                      return (
                                        !v ||
                                        v.length <= 255 ||
                                        "Máximo de 255 caracteres atingido"
                                      )
                                    }
                                  ],
                                  outlined: "",
                                  clearable: ""
                                },
                                model: {
                                  value: _vm.model.note,
                                  callback: function($$v) {
                                    _vm.$set(_vm.model, "note", $$v)
                                  },
                                  expression: "model.note"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "2" } },
                            [
                              _c("v-switch", {
                                staticClass: "pl-2",
                                attrs: { inset: "", label: "Professor Ativo" },
                                model: {
                                  value: _vm.model.active,
                                  callback: function($$v) {
                                    _vm.$set(_vm.model, "active", $$v)
                                  },
                                  expression: "model.active"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    { key: "extrato" },
                    [
                      _c("SysTeacherExtract", {
                        ref: "extract",
                        attrs: { teacher: _vm.model }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    { key: "licenca" },
                    [
                      _vm.renderComponent
                        ? _c("sys-teacher-license", {
                            attrs: {
                              showAdd: _vm.showAddLicense,
                              teacher: _vm.model
                            },
                            on: {
                              "update:showAdd": function($event) {
                                _vm.showAddLicense = $event
                              },
                              "update:show-add": function($event) {
                                _vm.showAddLicense = $event
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    { key: "atividade" },
                    [
                      _vm.renderComponent
                        ? _c("sys-teacher-activity", {
                            attrs: {
                              showAdd: _vm.showAddActivity,
                              teacher: _vm.model
                            },
                            on: {
                              "update:showAdd": function($event) {
                                _vm.showAddActivity = $event
                              },
                              "update:show-add": function($event) {
                                _vm.showAddActivity = $event
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    { key: "vinculo" },
                    [
                      _vm.renderComponent
                        ? _c("sys-teacher-contract", {
                            attrs: {
                              showAdd: _vm.showAddContract,
                              teacher: _vm.model
                            },
                            on: {
                              "update:showAdd": function($event) {
                                _vm.showAddContract = $event
                              },
                              "update:show-add": function($event) {
                                _vm.showAddContract = $event
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    { key: "endereco" },
                    [
                      _c("sys-address-register", {
                        attrs: { model: _vm.model, type: "teacher" },
                        on: {
                          "update:model": function($event) {
                            _vm.model = $event
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("emc-user-photos", {
                attrs: { model: _vm.model, show: _vm.dialogImages },
                on: {
                  "update:show": function($event) {
                    _vm.dialogImages = $event
                  }
                }
              })
            ],
            _c("emc-alert-snackbar", {
              attrs: {
                timeout: "10000",
                show: !!_vm.message.length,
                message: _vm.message,
                color: _vm.error ? "error" : "success"
              }
            })
          ]
        },
        proxy: true
      },
      {
        key: "actions",
        fn: function() {
          return [
            _vm.onlyHiring && _vm.model && _vm.model.id
              ? _c(
                  "v-btn",
                  {
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.changeStatus()
                      }
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.model.teacher_status_id == 2
                          ? "Tornar Ativo"
                          : "Tornar a Contratar"
                      )
                    ),
                    _c("v-icon", { attrs: { right: "", dark: "" } }, [
                      _vm._v(
                        _vm._s(
                          _vm.model.teacher_status_id == 2
                            ? "mdi-tag-outline"
                            : "mdi-tag-off-outline"
                        )
                      )
                    ])
                  ],
                  1
                )
              : _vm._e()
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }