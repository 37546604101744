var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.model,
      title: _vm.title,
      icon: _vm.icon,
      show: _vm.dialog,
      maxWidth: "50%"
    },
    on: {
      onSave: _vm.save,
      "update:model": function($event) {
        _vm.model = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-container",
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "8" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            autofocus: "",
                            error: !!_vm.errors.name,
                            "error-messages": _vm.errors.name,
                            label: "Nome*"
                          },
                          model: {
                            value: _vm.model.name,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "name", $$v)
                            },
                            expression: "model.name"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            error: !!_vm.errors.type,
                            "error-messages": _vm.errors.type,
                            label: "Tipo*"
                          },
                          model: {
                            value: _vm.model.type,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "type", $$v)
                            },
                            expression: "model.type"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("SysSelectEventScope", {
                          attrs: {
                            model: _vm.model.event_scope_id,
                            error:
                              _vm.errors.event_scope_id &&
                              _vm.errors.length > 0,
                            errors: _vm.errors.event_scope_id,
                            label: "Escopo do Evento*"
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(
                                _vm.model,
                                "event_scope_id",
                                $event
                              )
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "3" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            error: !!_vm.errors.day,
                            "error-messages": _vm.errors.day,
                            label: "Dia*"
                          },
                          model: {
                            value: _vm.model.day,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "day", $$v)
                            },
                            expression: "model.day"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "3" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            error: !!_vm.errors.month,
                            "error-messages": _vm.errors.month,
                            label: "Mês*"
                          },
                          model: {
                            value: _vm.model.month,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "month", $$v)
                            },
                            expression: "model.month"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _c("s-switch", {
                          attrs: {
                            inset: "",
                            "text-helper":
                              "A data de um evento não letivo não será considerado como dia de aula no ponto do professor",
                            label: "Evento Letivo"
                          },
                          model: {
                            value: _vm.model.school_day,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "school_day", $$v)
                            },
                            expression: "model.school_day"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _c("s-switch", {
                          attrs: {
                            inset: "",
                            "text-helper":
                              "Permitir Edição quando o usuário criar um novo evento",
                            color: "purple",
                            label: "Permitir Edição"
                          },
                          model: {
                            value: _vm.model.allow_editing,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "allow_editing", $$v)
                            },
                            expression: "model.allow_editing"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _c("sys-color-base", {
                          attrs: { color: _vm.model.color },
                          on: {
                            "update:color": function($event) {
                              return _vm.$set(_vm.model, "color", $event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c("v-textarea", {
                              attrs: {
                                outlined: "",
                                name: "input-7-4",
                                label: "Detalhes",
                                "auto-grow": false,
                                error: _vm.errors.length > 0,
                                "error-messages": _vm.errors.details,
                                value: _vm.model.details
                              },
                              model: {
                                value: _vm.model.details,
                                callback: function($$v) {
                                  _vm.$set(_vm.model, "details", $$v)
                                },
                                expression: "model.details"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("emc-alert-snackbar", {
              attrs: {
                show: !!_vm.message.length,
                message: _vm.message,
                color: _vm.error ? "error" : "success"
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }