var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.model,
      title: _vm.title,
      icon: _vm.icon,
      show: _vm.dialog,
      maxWidth: "30%"
    },
    on: {
      onSave: _vm.save,
      "update:model": function($event) {
        _vm.model = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-container",
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("sys-select-day-of-week", {
                          attrs: {
                            model: _vm.model.day_of_week_id,
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.day_of_week_id,
                            label: "Dia*"
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(
                                _vm.model,
                                "day_of_week_id",
                                $event
                              )
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("sys-select-shift", {
                          attrs: {
                            model: _vm.model.shift_id,
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.shift_id,
                            label: "Turno*",
                            maxShift: 4
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(_vm.model, "shift_id", $event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("emc-calendar-time-picker", {
                          attrs: {
                            error: !!_vm.errors.hour_start,
                            errors: _vm.errors.hour_start,
                            time: _vm.model.hour_start,
                            label: "Início*"
                          },
                          on: {
                            "update:time": function($event) {
                              return _vm.$set(_vm.model, "hour_start", $event)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("emc-calendar-time-picker", {
                          attrs: {
                            error: !!_vm.errors.hour_end,
                            errors: _vm.errors.hour_end,
                            time: _vm.model.hour_end,
                            label: "Fim*"
                          },
                          on: {
                            "update:time": function($event) {
                              return _vm.$set(_vm.model, "hour_end", $event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            dense: "",
                            error: !!_vm.errors.total_classes,
                            "error-messages": _vm.errors.total_classes,
                            label: "Total de Aulas*"
                          },
                          model: {
                            value: _vm.model.total_classes,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "total_classes", $$v)
                            },
                            expression: "model.total_classes"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("emc-calendar-date-picker2", {
                          attrs: {
                            model: _vm.model.date,
                            label: "Data",
                            errorMessages: _vm.errors.date,
                            show: _vm.dialog,
                            showClear: ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(_vm.model, "date", $event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "12" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            error: !!_vm.errors.description,
                            "error-messages": _vm.errors.description,
                            label: "Descrição"
                          },
                          model: {
                            value: _vm.model.description,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "description", $$v)
                            },
                            expression: "model.description"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("v-switch", {
                          attrs: { inset: "", label: "Ativo" },
                          model: {
                            value: _vm.model.active,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "active", $$v)
                            },
                            expression: "model.active"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("emc-alert-snackbar", {
              attrs: {
                show: !!_vm.message.length,
                message: _vm.message,
                color: _vm.error ? "error" : "success"
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }