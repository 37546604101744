var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.model,
      title: _vm.title,
      icon: _vm.icon,
      show: _vm.dialog,
      maxWidth: "50%"
    },
    on: {
      onSave: _vm.save,
      "update:model": function($event) {
        _vm.model = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-tabs",
              {
                staticClass: "ma-0",
                model: {
                  value: _vm.tab,
                  callback: function($$v) {
                    _vm.tab = $$v
                  },
                  expression: "tab"
                }
              },
              [
                _c("v-tab", { key: "main" }, [_vm._v("Dados Principais")]),
                _c("v-tab", { key: "work-regime" }, [
                  _vm._v("Regime de Trabalho")
                ])
              ],
              1
            ),
            _c(
              "v-tabs-items",
              {
                model: {
                  value: _vm.tab,
                  callback: function($$v) {
                    _vm.tab = $$v
                  },
                  expression: "tab"
                }
              },
              [
                _c(
                  "v-tab-item",
                  { key: "main" },
                  [
                    _c(
                      "v-container",
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "12" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    autofocus: "",
                                    error: !!_vm.errors.name,
                                    "error-messages": _vm.errors.name,
                                    label: "Nome*"
                                  },
                                  model: {
                                    value: _vm.model.name,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "name", $$v)
                                    },
                                    expression: "model.name"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "12" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    error: !!_vm.errors.description,
                                    "error-messages": _vm.errors.description,
                                    label: "Descrição"
                                  },
                                  model: {
                                    value: _vm.model.description,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "description", $$v)
                                    },
                                    expression: "model.description"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "6" } },
                              [
                                _c("sys-select-hour-group", {
                                  attrs: {
                                    model: _vm.model.hour_group_id,
                                    label: "Grupo de Horário",
                                    showClear: "",
                                    dense: ""
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "hour_group_id",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "3" } },
                              [
                                _c("s-text-field", {
                                  attrs: {
                                    dense: "",
                                    code: "",
                                    "error-messages": _vm.errors.code,
                                    label: "Código"
                                  },
                                  model: {
                                    value: _vm.model.code,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "code", $$v)
                                    },
                                    expression: "model.code"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "3" } },
                              [
                                _c("v-switch", {
                                  attrs: { inset: "", label: "Ativa" },
                                  model: {
                                    value: _vm.model.active,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "active", $$v)
                                    },
                                    expression: "model.active"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-tab-item",
                  { key: "work-regime" },
                  [
                    _c(
                      "v-container",
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "4" } },
                              [
                                _c("sys-select-teacher-work-regime", {
                                  attrs: {
                                    model: _vm.model.teacher_work_regime_id,
                                    error: _vm.errors.length > 0,
                                    errors: _vm.errors.teacher_work_regime_id,
                                    label: "Regime de Trabalho",
                                    showClear: ""
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "teacher_work_regime_id",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "4" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    error: !!_vm.errors.min_workload,
                                    "error-messages": _vm.errors.min_workload,
                                    label: "Carga Horária Mínima"
                                  },
                                  model: {
                                    value: _vm.model.min_workload,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "min_workload", $$v)
                                    },
                                    expression: "model.min_workload"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "4" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    error: !!_vm.errors.max_workload,
                                    "error-messages": _vm.errors.max_workload,
                                    label: "Carga Horária Máxima"
                                  },
                                  model: {
                                    value: _vm.model.max_workload,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "max_workload", $$v)
                                    },
                                    expression: "model.max_workload"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "6" } },
                              [
                                _c("v-switch", {
                                  attrs: {
                                    inset: "",
                                    label: "Complementar CH"
                                  },
                                  model: {
                                    value: _vm.model.requires_complementary,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.model,
                                        "requires_complementary",
                                        $$v
                                      )
                                    },
                                    expression: "model.requires_complementary"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "6" } },
                              [
                                _c("v-switch", {
                                  attrs: {
                                    inset: "",
                                    label: "Prioridade de Atribuição"
                                  },
                                  model: {
                                    value: _vm.model.is_priority,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "is_priority", $$v)
                                    },
                                    expression: "model.is_priority"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("emc-alert-snackbar", {
              attrs: {
                show: !!_vm.message.length,
                message: _vm.message,
                color: _vm.error ? "error" : "success"
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }