var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("sys-register-base", {
        attrs: {
          loading: _vm.loading,
          model: _vm.model,
          title: _vm.title,
          icon: "mdi-flask-outline",
          show: _vm.dialog,
          maxWidth: "60%"
        },
        on: {
          onSave: _vm.save,
          "update:model": function($event) {
            _vm.model = $event
          },
          "update:show": function($event) {
            _vm.dialog = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "tools",
            fn: function() {
              return [
                _vm.model.id
                  ? _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          attrs: { icon: "" },
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              $event.stopPropagation()
                                              _vm.dialogImages = true
                                            }
                                          }
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "cyan" } },
                                        [_vm._v("mdi-camera-image")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1798115446
                        )
                      },
                      [_c("span", [_vm._v("Imagens do Ambiente")])]
                    )
                  : _vm._e()
              ]
            },
            proxy: true
          },
          {
            key: "form",
            fn: function() {
              return [
                _c(
                  "v-tabs",
                  {
                    staticClass: "ma-0",
                    model: {
                      value: _vm.tab,
                      callback: function($$v) {
                        _vm.tab = $$v
                      },
                      expression: "tab"
                    }
                  },
                  [
                    _c("v-tab", { key: "main" }, [_vm._v("Dados Principais")]),
                    _c("v-tab", { key: "resources" }, [_vm._v("Recursos")]),
                    _vm.model && _vm.model.id
                      ? _c("v-tab", { key: "availability" }, [
                          _vm._v("Disponibilidade")
                        ])
                      : _vm._e(),
                    _vm.model && _vm.model.id
                      ? _c("v-tab", { key: "allocation" }, [
                          _vm._v("Agendamento Externo")
                        ])
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "v-tabs-items",
                  {
                    model: {
                      value: _vm.tab,
                      callback: function($$v) {
                        _vm.tab = $$v
                      },
                      expression: "tab"
                    }
                  },
                  [
                    _c(
                      "v-tab-item",
                      { key: "main" },
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "7" } },
                              [
                                _c("SysSelectCampus", {
                                  attrs: {
                                    model: _vm.model.campus_id,
                                    error: _vm.errors.length > 0,
                                    errors: _vm.errors.campus_id,
                                    label: "Campus*",
                                    "show-add": false,
                                    "auto-complete": ""
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "campus_id",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "5" } },
                              [
                                _c("SysSelectAmbienceType", {
                                  attrs: {
                                    model: _vm.model.ambience_type_id,
                                    error: _vm.errors.length > 0,
                                    errors: _vm.errors.ambience_type_id,
                                    label: "Tipo de Ambiente*",
                                    showAdd: ""
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "ambience_type_id",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "4" } },
                              [
                                _c("s-text-field", {
                                  attrs: {
                                    "text-helper":
                                      "Nome ou número do ambiente.",
                                    dense: "",
                                    "error-messages": _vm.errors.name,
                                    label: "Número/Nome*"
                                  },
                                  model: {
                                    value: _vm.model.name,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "name", $$v)
                                    },
                                    expression: "model.name"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "8" } },
                              [
                                _c("s-text-field", {
                                  attrs: {
                                    "text-helper": "Descrição do Ambiente",
                                    dense: "",
                                    "error-messages": _vm.errors.description,
                                    label: "Descrição"
                                  },
                                  model: {
                                    value: _vm.model.description,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "description", $$v)
                                    },
                                    expression: "model.description"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "4" } },
                              [
                                _c("SysSelectPlace", {
                                  attrs: {
                                    model: _vm.model.place_id,
                                    error: _vm.errors.length > 0,
                                    errors: _vm.errors.place_id,
                                    label: "Prédio",
                                    "item-text": "name_complete",
                                    clearable: "",
                                    "auto-complete": "",
                                    "campus-id": _vm.model.campus_id
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "place_id",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "4" } },
                              [
                                _c("SysSelectBlock", {
                                  attrs: {
                                    model: _vm.model.block_id,
                                    error: _vm.errors.length > 0,
                                    errors: _vm.errors.block_id,
                                    label: "Bloco",
                                    "item-text": "name_complete",
                                    clearable: "",
                                    "auto-complete": "",
                                    "place-id": _vm.model.place_id
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "block_id",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "4" } },
                              [
                                _c("SysSelectFloor", {
                                  attrs: {
                                    model: _vm.model.floor_id,
                                    error: _vm.errors.length > 0,
                                    errors: _vm.errors.floor_id,
                                    label: "Andar*",
                                    "auto-complete": ""
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "floor_id",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "4" } },
                              [
                                _c("SysSelectCategoryAmbience", {
                                  attrs: {
                                    model: _vm.model.category_ambience_id,
                                    error: _vm.errors.length > 0,
                                    errors: _vm.errors.category_ambience_id,
                                    clearable: "",
                                    label: "Categoria de Ambiente",
                                    dense: ""
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "category_ambience_id",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "2" } },
                              [
                                _c("s-text-field", {
                                  attrs: {
                                    "text-helper":
                                      "Capacidade padrão do ambiente.",
                                    dense: "",
                                    "error-messages": _vm.errors.capacity,
                                    label: "Capacidade*"
                                  },
                                  model: {
                                    value: _vm.model.capacity,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "capacity", $$v)
                                    },
                                    expression: "model.capacity"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "2" } },
                              [
                                _c("s-text-field", {
                                  attrs: {
                                    "text-helper":
                                      "Capacidade máxima, além da capacidade padrão do ambiente.",
                                    dense: "",
                                    "error-messages":
                                      _vm.errors.extended_capacity,
                                    label: "Cap. Est*"
                                  },
                                  model: {
                                    value: _vm.model.extended_capacity,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.model,
                                        "extended_capacity",
                                        $$v
                                      )
                                    },
                                    expression: "model.extended_capacity"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "2" } },
                              [
                                _c("s-text-field", {
                                  attrs: {
                                    "text-helper":
                                      "Capacidade reduzida, caso seja necessário reduzir a capacidade padrão do ambiente.",
                                    dense: "",
                                    "error-messages":
                                      _vm.errors.reduced_capacity,
                                    label: "Cap Red*"
                                  },
                                  model: {
                                    value: _vm.model.reduced_capacity,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.model,
                                        "reduced_capacity",
                                        $$v
                                      )
                                    },
                                    expression: "model.reduced_capacity"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "2" } },
                              [
                                _c("s-text-field", {
                                  attrs: {
                                    "text-helper": "Metragem do Ambiente.",
                                    dense: "",
                                    "error-messages": _vm.errors.area,
                                    label: "Área*"
                                  },
                                  model: {
                                    value: _vm.model.area,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "area", $$v)
                                    },
                                    expression: "model.area"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "3" } },
                              [
                                _c("s-text-field", {
                                  attrs: {
                                    code: "",
                                    "error-messages": _vm.errors.code,
                                    label: "Código"
                                  },
                                  model: {
                                    value: _vm.model.code,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "code", $$v)
                                    },
                                    expression: "model.code"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "3" } },
                              [
                                _c("s-text-field", {
                                  attrs: {
                                    "text-helper": "Código da Sala Externo",
                                    code: "",
                                    "error-messages": _vm.errors.code_control,
                                    label: "Código Sala"
                                  },
                                  model: {
                                    value: _vm.model.code_control,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "code_control", $$v)
                                    },
                                    expression: "model.code_control"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm.user.is_master
                              ? _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "3" } },
                                  [
                                    _c("s-switch", {
                                      attrs: {
                                        "text-helper":
                                          "Validar dados cadastrados do ambiente",
                                        color: "purple",
                                        inset: "",
                                        label: "Validado"
                                      },
                                      model: {
                                        value: _vm.model.validated,
                                        callback: function($$v) {
                                          _vm.$set(_vm.model, "validated", $$v)
                                        },
                                        expression: "model.validated"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "3" } },
                              [
                                _c("s-switch", {
                                  attrs: {
                                    "text-helper":
                                      "Ambiente não existe mais ou não será mais utilizado",
                                    inset: "",
                                    label: "Ativo"
                                  },
                                  model: {
                                    value: _vm.model.active,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "active", $$v)
                                    },
                                    expression: "model.active"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-tab-item",
                      { key: "resources" },
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "12" } },
                              [
                                _c("v-data-table", {
                                  staticClass: "elevation-1",
                                  attrs: {
                                    headers: _vm.headers,
                                    items: _vm.resources,
                                    "hide-default-footer": true,
                                    "items-per-page": -1
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "top",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    color: "indigo darken-1",
                                                    icon: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.preventDefault()
                                                      $event.stopPropagation()
                                                      return _vm.addItem()
                                                    }
                                                  }
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c("v-icon", [_vm._v("mdi-plus")])
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    },
                                    {
                                      key: "item.resource",
                                      fn: function(ref) {
                                        var item = ref.item
                                        var index = ref.index
                                        return [
                                          _c("sys-select-resource", {
                                            attrs: {
                                              model: item.resource_id,
                                              error: _vm.errors.length > 0,
                                              errors:
                                                _vm.errors[
                                                  "resources." +
                                                    index +
                                                    ".resource_id"
                                                ],
                                              label: "",
                                              dense: "",
                                              showAdd: "",
                                              "auto-complete": "",
                                              "item-text": "name_complete"
                                            },
                                            on: {
                                              "update:model": function($event) {
                                                return _vm.$set(
                                                  item,
                                                  "resource_id",
                                                  $event
                                                )
                                              }
                                            }
                                          })
                                        ]
                                      }
                                    },
                                    {
                                      key: "item.total",
                                      fn: function(ref) {
                                        var item = ref.item
                                        var index = ref.index
                                        return [
                                          _c("v-text-field", {
                                            attrs: {
                                              error: _vm.errors.length > 0,
                                              "error-messages":
                                                _vm.errors[
                                                  "resources." +
                                                    index +
                                                    ".total"
                                                ],
                                              dense: ""
                                            },
                                            model: {
                                              value: item.total,
                                              callback: function($$v) {
                                                _vm.$set(item, "total", $$v)
                                              },
                                              expression: "item.total"
                                            }
                                          })
                                        ]
                                      }
                                    },
                                    {
                                      key: "item.allow_external_allocation",
                                      fn: function(ref) {
                                        var item = ref.item
                                        return [
                                          _c("s-switch", {
                                            attrs: {
                                              "text-helper":
                                                "Recurso disponível para agendamento externo",
                                              inset: ""
                                            },
                                            model: {
                                              value:
                                                item.allow_external_allocation,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  item,
                                                  "allow_external_allocation",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "item.allow_external_allocation"
                                            }
                                          })
                                        ]
                                      }
                                    },
                                    {
                                      key: "item.active",
                                      fn: function(ref) {
                                        var item = ref.item
                                        return [
                                          _c("v-switch", {
                                            attrs: { inset: "" },
                                            model: {
                                              value: item.active,
                                              callback: function($$v) {
                                                _vm.$set(item, "active", $$v)
                                              },
                                              expression: "item.active"
                                            }
                                          })
                                        ]
                                      }
                                    },
                                    {
                                      key: "item.actions",
                                      fn: function(ref) {
                                        var item = ref.item
                                        return [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                small: "",
                                                color: "error",
                                                dense: ""
                                              },
                                              on: {
                                                click: function($event) {
                                                  $event.preventDefault()
                                                  $event.stopPropagation()
                                                  return _vm.deleteItem(item)
                                                }
                                              }
                                            },
                                            [_vm._v(" mdi-delete ")]
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-tab-item",
                      { key: "availability" },
                      [
                        _c("sys-availability-register", {
                          attrs: { model: _vm.model }
                        }),
                        _c("s-switch", {
                          staticClass: "ml-3",
                          attrs: {
                            "text-helper":
                              "Caso o ambiente esteja temporariamente indisponível para uso.",
                            inset: "",
                            color: "purple",
                            label: "Indisponível"
                          },
                          model: {
                            value: _vm.model.not_available,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "not_available", $$v)
                            },
                            expression: "model.not_available"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-tab-item",
                      { key: "allocation" },
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "12" } },
                              [
                                _c("s-switch", {
                                  staticClass: "ml-3",
                                  attrs: {
                                    "text-helper":
                                      "Ambiente disponível para consultas e agendamentos externos",
                                    inset: "",
                                    label: "Disponível para agendamento externo"
                                  },
                                  model: {
                                    value: _vm.model.allow_external_allocation,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.model,
                                        "allow_external_allocation",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "model.allow_external_allocation"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "3" } },
                              [
                                _c("emc-calendar-date-picker2", {
                                  attrs: {
                                    model:
                                      _vm.model.date_start_external_allocation,
                                    label: "Data Início",
                                    errorMessages:
                                      _vm.errors.date_start_external_allocation,
                                    show: _vm.dialog,
                                    disabled: !_vm.model
                                      .allow_external_allocation,
                                    clearable: ""
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "date_start_external_allocation",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "3" } },
                              [
                                _c("emc-calendar-date-picker2", {
                                  attrs: {
                                    model:
                                      _vm.model.date_end_external_allocation,
                                    label: "Data Fim",
                                    errorMessages:
                                      _vm.errors.date_end_external_allocation,
                                    show: _vm.dialog,
                                    disabled: !_vm.model
                                      .allow_external_allocation,
                                    clearable: ""
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "date_end_external_allocation",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "3" } },
                              [
                                _c("emc-calendar-time-picker", {
                                  attrs: {
                                    time:
                                      _vm.model.hour_start_external_allocation,
                                    error: !!_vm.errors
                                      .hour_start_external_allocation,
                                    errors:
                                      _vm.errors.hour_start_external_allocation,
                                    label: "Hora Início",
                                    dense: "",
                                    disabled: !_vm.model
                                      .allow_external_allocation,
                                    clearable: ""
                                  },
                                  on: {
                                    "update:time": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "hour_start_external_allocation",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "3" } },
                              [
                                _c("emc-calendar-time-picker", {
                                  attrs: {
                                    time:
                                      _vm.model.hour_end_external_allocation,
                                    error: !!_vm.errors
                                      .hour_end_external_allocation,
                                    errors:
                                      _vm.errors.hour_end_external_allocation,
                                    label: "Hora Fim",
                                    dense: "",
                                    disabled: !_vm.model
                                      .allow_external_allocation,
                                    clearable: ""
                                  },
                                  on: {
                                    "update:time": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "hour_end_external_allocation",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "6" } },
                              [
                                _c("v-select", {
                                  attrs: {
                                    "item-value": "id",
                                    "item-text": "name",
                                    items: _vm.days.filter(function(d) {
                                      return d.visible
                                    }),
                                    label: "Dias não Disponíveis",
                                    dense: "",
                                    multiple: ""
                                  },
                                  model: {
                                    value: _vm.model.allocationDays,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "allocationDays", $$v)
                                    },
                                    expression: "model.allocationDays"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("emc-alert-snackbar", {
                  attrs: {
                    show: !!_vm.message.length,
                    message: _vm.message,
                    color: _vm.error ? "error" : "success"
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("sys-ambience-images", {
        attrs: { ambience: _vm.model, show: _vm.dialogImages },
        on: {
          "update:show": function($event) {
            _vm.dialogImages = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }