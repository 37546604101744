var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-main",
    [
      _c(
        "v-container",
        { attrs: { fluid: "", "fill-height": "" } },
        [
          _c(
            "v-layout",
            { attrs: { "align-center": "", "justify-center": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "", sm8: "", md4: "" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "elevation-12" },
                    [
                      _c(
                        "v-toolbar",
                        { attrs: { dark: "", color: "teal" } },
                        [
                          _c("v-toolbar-title", [
                            _vm._v("SysClass - Acesso Professor")
                          ]),
                          _c("v-spacer")
                        ],
                        1
                      ),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-alert",
                            {
                              staticClass: "text-xs-center",
                              attrs: {
                                dense: "",
                                outlined: "",
                                color: "primary"
                              }
                            },
                            [
                              _c("div", { staticClass: "text-center" }, [
                                !_vm.modeSendToken
                                  ? _c("strong", [
                                      _vm._v(
                                        " Informe um dos dados abaixo para receber por e-mail o link para acessar sua página! "
                                      )
                                    ])
                                  : _c("strong", [
                                      _vm._v(
                                        " Informe um dos dados abaixo para obter o token de acesso!! "
                                      )
                                    ])
                              ])
                            ]
                          ),
                          _c(
                            "v-tabs",
                            {
                              model: {
                                value: _vm.tab,
                                callback: function($$v) {
                                  _vm.tab = $$v
                                },
                                expression: "tab"
                              }
                            },
                            [
                              _c("v-tabs-slider"),
                              _c("v-tab", { attrs: { href: "#email" } }, [
                                _c(
                                  "span",
                                  [
                                    _c("v-icon", { attrs: { small: "" } }, [
                                      _vm._v("mdi-mail")
                                    ]),
                                    _vm._v(" Email")
                                  ],
                                  1
                                )
                              ]),
                              _c("v-tab", { attrs: { href: "#phone" } }, [
                                _c(
                                  "span",
                                  [
                                    _c("v-icon", { attrs: { small: "" } }, [
                                      _vm._v("mdi-cellphone-iphone")
                                    ]),
                                    _vm._v(" Celular")
                                  ],
                                  1
                                )
                              ]),
                              _c("v-tab", { attrs: { href: "#data" } }, [
                                _c(
                                  "span",
                                  [
                                    _c("v-icon", { attrs: { small: "" } }, [
                                      _vm._v("mdi-card-account-details-outline")
                                    ]),
                                    _vm._v(" Meus Dados")
                                  ],
                                  1
                                )
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-tabs-items",
                            {
                              model: {
                                value: _vm.tab,
                                callback: function($$v) {
                                  _vm.tab = $$v
                                },
                                expression: "tab"
                              }
                            },
                            [
                              _c(
                                "v-tab-item",
                                { attrs: { value: "email" } },
                                [
                                  _c(
                                    "v-card",
                                    { attrs: { flat: "" } },
                                    [
                                      _c(
                                        "v-card-text",
                                        [
                                          _c("v-text-field", {
                                            ref: "mail",
                                            attrs: {
                                              error: _vm.errors.length > 0,
                                              "error-messages":
                                                _vm.errors.email,
                                              label:
                                                "Informe seu e-mail cadastrado",
                                              required: "",
                                              dense: "",
                                              autofocus: "",
                                              "append-icon": "mdi-mail"
                                            },
                                            model: {
                                              value: _vm.model.email,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.model,
                                                  "email",
                                                  $$v
                                                )
                                              },
                                              expression: "model.email"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-tab-item",
                                { attrs: { value: "phone" } },
                                [
                                  _c(
                                    "v-card",
                                    { attrs: { flat: "" } },
                                    [
                                      _c(
                                        "v-card-text",
                                        [
                                          _c("v-text-field", {
                                            directives: [
                                              {
                                                name: "mask-phone",
                                                rawName: "v-mask-phone.br",
                                                modifiers: { br: true }
                                              }
                                            ],
                                            ref: "phone",
                                            attrs: {
                                              "append-icon":
                                                "mdi-cellphone-iphone",
                                              error: _vm.errors.length > 0,
                                              "error-messages":
                                                _vm.errors.mobile_phone,
                                              label: "Celular",
                                              autofocus: "",
                                              dense: ""
                                            },
                                            model: {
                                              value: _vm.model.mobile_phone,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.model,
                                                  "mobile_phone",
                                                  $$v
                                                )
                                              },
                                              expression: "model.mobile_phone"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-tab-item",
                                { attrs: { value: "data" } },
                                [
                                  _c(
                                    "v-card",
                                    { attrs: { flat: "" } },
                                    [
                                      _c(
                                        "v-card-text",
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: { cols: "12", sm: "6" }
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    directives: [
                                                      {
                                                        name: "mask-cpf",
                                                        rawName: "v-mask-cpf"
                                                      }
                                                    ],
                                                    ref: "numberCpf",
                                                    attrs: {
                                                      "append-icon":
                                                        "mdi-card-account-details-outline",
                                                      maxlength: "14",
                                                      error:
                                                        _vm.errors.length > 0,
                                                      "error-messages":
                                                        _vm.errors.number_cpf,
                                                      label: "CPF",
                                                      disabled: _vm.disabled,
                                                      autofocus: "",
                                                      dense: ""
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.model.number_cpf,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.model,
                                                          "number_cpf",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "model.number_cpf"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: { cols: "12", sm: "6" }
                                                },
                                                [
                                                  _c(
                                                    "emc-calendar-date-picker2",
                                                    {
                                                      attrs: {
                                                        label:
                                                          "Data Nascimento",
                                                        model:
                                                          _vm.model.birth_date,
                                                        errorMessages:
                                                          _vm.errors.birth_date,
                                                        show: _vm.dialog,
                                                        disabled: _vm.disabled,
                                                        showClear: "",
                                                        readonly: false,
                                                        openOnClick: false,
                                                        dense: ""
                                                      },
                                                      on: {
                                                        "update:model": function(
                                                          $event
                                                        ) {
                                                          return _vm.$set(
                                                            _vm.model,
                                                            "birth_date",
                                                            $event
                                                          )
                                                        }
                                                      }
                                                    }
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm.tab == "data" && _vm.items.length > 0
                            ? _c(
                                "v-row",
                                [
                                  _c(
                                    "v-card",
                                    {
                                      staticClass: "mx-auto",
                                      attrs: { "max-width": "300", tile: "" }
                                    },
                                    [
                                      _c(
                                        "v-list",
                                        { attrs: { dense: "" } },
                                        [
                                          _c("v-subheader", [
                                            _vm._v(
                                              "COMO DESEJA RECEBER SEU TOKEN?"
                                            )
                                          ]),
                                          _c(
                                            "v-list-item-group",
                                            {
                                              attrs: { color: "primary" },
                                              model: {
                                                value: _vm.selectedItem,
                                                callback: function($$v) {
                                                  _vm.selectedItem = $$v
                                                },
                                                expression: "selectedItem"
                                              }
                                            },
                                            _vm._l(_vm.items, function(
                                              item,
                                              i
                                            ) {
                                              return _c(
                                                "v-list-item",
                                                { key: i },
                                                [
                                                  _c(
                                                    "v-list-item-icon",
                                                    [
                                                      _c("v-icon", {
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            item.icon
                                                          )
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-list-item-content",
                                                    [
                                                      _c("v-list-item-title", {
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            item.text
                                                          )
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            }),
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.modeSendToken
                            ? _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c("emc-text-token", {
                                        ref: "elToken",
                                        attrs: {
                                          model: _vm.token,
                                          loading: _vm.loadingToken,
                                          disabled: _vm.disabled
                                        },
                                        on: {
                                          "update:model": function($event) {
                                            _vm.token = $event
                                          },
                                          complete: function($event) {
                                            return _vm.access($event)
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "ml-1 mb-1",
                              attrs: { small: "", text: "", color: "primary" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  $event.stopPropagation()
                                  return _vm.$router.push({ name: "login" })
                                }
                              }
                            },
                            [_vm._v("Tela de Login")]
                          ),
                          _c("v-spacer"),
                          _vm.modeSendToken
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "mr-4",
                                  staticStyle: { cursor: "pointer" },
                                  attrs: {
                                    color: "primary",
                                    loading: _vm.loading,
                                    disabled: _vm.disabled
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.sendToken()
                                    }
                                  }
                                },
                                [
                                  _vm._v(" Obter Token "),
                                  _c("v-icon", [_vm._v("mdi-send")])
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.modeSendToken
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "mr-4",
                                  staticStyle: { cursor: "pointer" },
                                  attrs: {
                                    color: "primary",
                                    loading: _vm.loading,
                                    disabled: _vm.disabled
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.sendMail()
                                    }
                                  }
                                },
                                [
                                  _vm._v(" Receber Link "),
                                  _c("v-icon", [_vm._v("mdi-send")])
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "pa-1 ma-1 text-center" },
                        [
                          _c("v-alert", {
                            attrs: { type: _vm.type },
                            domProps: { innerHTML: _vm._s(_vm.message) },
                            model: {
                              value: _vm.snackbar,
                              callback: function($$v) {
                                _vm.snackbar = $$v
                              },
                              expression: "snackbar"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }