var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.model,
      title: _vm.title,
      icon: _vm.icon,
      show: _vm.dialog,
      maxWidth: "40%"
    },
    on: {
      onSave: _vm.save,
      "update:model": function($event) {
        _vm.model = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-container",
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("sys-select-periodicity", {
                          attrs: {
                            model: _vm.model.periodicity_type_id,
                            error:
                              _vm.errors.periodicity_type_id &&
                              _vm.errors.length > 0,
                            errors: _vm.errors.periodicity_type_id,
                            label: "Periodicidade*",
                            dense: ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(
                                _vm.model,
                                "periodicity_type_id",
                                $event
                              )
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("sys-select-day-of-week", {
                          attrs: {
                            model: _vm.model.day_of_week_id,
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.day_of_week_id,
                            label: "Dia*",
                            dense: ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(
                                _vm.model,
                                "day_of_week_id",
                                $event
                              )
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("sys-select-date-mask-type", {
                          attrs: {
                            model: _vm.model.date_mask_type_id,
                            error:
                              _vm.errors.date_mask_type_id &&
                              _vm.errors.length > 0,
                            errors: _vm.errors.date_mask_type_id,
                            label: "Máscara*",
                            dense: "",
                            "show-add": ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(
                                _vm.model,
                                "date_mask_type_id",
                                $event
                              )
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("emc-calendar-date-picker2", {
                          attrs: {
                            model: _vm.model.date,
                            label: "Data*",
                            errorMessages: _vm.errors.date,
                            show: _vm.dialog,
                            dense: ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(_vm.model, "date", $event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _c("sys-select-shift", {
                          attrs: {
                            model: _vm.model.shift_id,
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.shift_id,
                            label: "Turno",
                            showClear: "",
                            dense: ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(_vm.model, "shift_id", $event)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _c("emc-calendar-time-picker", {
                          attrs: {
                            dense: "",
                            error: !!_vm.errors.hour_start,
                            errors: _vm.errors["hour_start"],
                            time: _vm.model.hour_start,
                            label: "Início",
                            clearable: ""
                          },
                          on: {
                            "update:time": function($event) {
                              return _vm.$set(_vm.model, "hour_start", $event)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _c("emc-calendar-time-picker", {
                          attrs: {
                            dense: "",
                            error: !!_vm.errors.hour_end,
                            errors: _vm.errors["hour_end"],
                            time: _vm.model.hour_end,
                            label: "Fim",
                            clearable: ""
                          },
                          on: {
                            "update:time": function($event) {
                              return _vm.$set(_vm.model, "hour_end", $event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("emc-alert-snackbar", {
              attrs: {
                show: !!_vm.message.length,
                message: _vm.message,
                color: _vm.error ? "error" : "success"
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }