var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.model,
      title: _vm.title,
      maxWidth: "70%",
      icon: "mdi-calendar-plus",
      show: _vm.dialog
    },
    on: {
      onSave: _vm.save,
      "update:model": function($event) {
        _vm.model = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "tools",
        fn: function() {
          return [
            _c("sys-work-time-pop-over", {
              attrs: { model: _vm.model, errors: _vm.errors }
            })
          ]
        },
        proxy: true
      },
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-tabs",
              {
                staticClass: "ma-0",
                model: {
                  value: _vm.tab,
                  callback: function($$v) {
                    _vm.tab = $$v
                  },
                  expression: "tab"
                }
              },
              [
                _c("v-tab", { key: "main" }, [_vm._v("Dados Principais")]),
                _c("v-tab", { key: "dates" }, [_vm._v("Sub-Períodos")]),
                _c("v-tab", { key: "integration" }, [_vm._v("Integração")])
              ],
              1
            ),
            _c(
              "v-tabs-items",
              {
                model: {
                  value: _vm.tab,
                  callback: function($$v) {
                    _vm.tab = $$v
                  },
                  expression: "tab"
                }
              },
              [
                _c(
                  "v-tab-item",
                  { key: "main" },
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "6" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                error: _vm.errors.length > 0,
                                autofocus: true,
                                "error-messages": _vm.errors.name,
                                label: "Nome*",
                                required: ""
                              },
                              model: {
                                value: _vm.model.name,
                                callback: function($$v) {
                                  _vm.$set(_vm.model, "name", $$v)
                                },
                                expression: "model.name"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "3" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                error: _vm.errors.length > 0,
                                "error-messages": _vm.errors.year,
                                label: "Ano*",
                                required: ""
                              },
                              model: {
                                value: _vm.model.year,
                                callback: function($$v) {
                                  _vm.$set(_vm.model, "year", $$v)
                                },
                                expression: "model.year"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "3" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                error: _vm.errors.length > 0,
                                "error-messages": _vm.errors.period,
                                label: "Período*",
                                required: ""
                              },
                              model: {
                                value: _vm.model.period,
                                callback: function($$v) {
                                  _vm.$set(_vm.model, "period", $$v)
                                },
                                expression: "model.period"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "6" } },
                          [
                            _c("emc-calendar-date-picker-range", {
                              attrs: {
                                textHelper:
                                  "Data de início e fim do Período Letivo.",
                                errorMessages1: _vm.errors.start,
                                errorMessages2: _vm.errors.end,
                                show: _vm.dialog,
                                model1: _vm.model.start,
                                model2: _vm.model.end,
                                label: "Início/Fim*"
                              },
                              on: {
                                "update:model1": function($event) {
                                  return _vm.$set(_vm.model, "start", $event)
                                },
                                "update:model2": function($event) {
                                  return _vm.$set(_vm.model, "end", $event)
                                }
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "6" } },
                          [
                            _c("emc-calendar-date-picker-range", {
                              attrs: {
                                textHelper: "Data de início e fim das aulas.",
                                errorMessages1: _vm.errors.classes_start,
                                errorMessages2: _vm.errors.classes_end,
                                show: _vm.dialog,
                                model1: _vm.model.classes_start,
                                model2: _vm.model.classes_end,
                                label: "Período de Aulas*"
                              },
                              on: {
                                "update:model1": function($event) {
                                  return _vm.$set(
                                    _vm.model,
                                    "classes_start",
                                    $event
                                  )
                                },
                                "update:model2": function($event) {
                                  return _vm.$set(
                                    _vm.model,
                                    "classes_end",
                                    $event
                                  )
                                }
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "6" } },
                          [
                            _c("emc-calendar-date-picker-range", {
                              attrs: {
                                textHelper:
                                  "Período de cadastro da disponibilidade pelo professor.",
                                errorMessages1: _vm.errors.availability_start,
                                errorMessages2: _vm.errors.availability_end,
                                show: _vm.dialog,
                                model1: _vm.model.availability_start,
                                model2: _vm.model.availability_end,
                                label: "Período Disponibilidade*"
                              },
                              on: {
                                "update:model1": function($event) {
                                  return _vm.$set(
                                    _vm.model,
                                    "availability_start",
                                    $event
                                  )
                                },
                                "update:model2": function($event) {
                                  return _vm.$set(
                                    _vm.model,
                                    "availability_end",
                                    $event
                                  )
                                }
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "6" } },
                          [
                            _c("emc-calendar-date-picker-range", {
                              attrs: {
                                textHelper:
                                  "Período de atribuição de aulas para os professores.",
                                errorMessages1: _vm.errors.indication_start,
                                errorMessages2: _vm.errors.indication_end,
                                show: _vm.dialog,
                                model1: _vm.model.indication_start,
                                model2: _vm.model.indication_end,
                                label: "Período de Atribuição*"
                              },
                              on: {
                                "update:model1": function($event) {
                                  return _vm.$set(
                                    _vm.model,
                                    "indication_start",
                                    $event
                                  )
                                },
                                "update:model2": function($event) {
                                  return _vm.$set(
                                    _vm.model,
                                    "indication_end",
                                    $event
                                  )
                                }
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "6" } },
                          [
                            _c("emc-calendar-date-picker-range", {
                              attrs: {
                                dense: "",
                                textHelper:
                                  "Período de confirmação de carga horária dos professores.",
                                clearable: "",
                                errorMessages1: _vm.errors.confirmation_start,
                                errorMessages2: _vm.errors.confirmation_end,
                                show: _vm.dialog,
                                model1: _vm.model.confirmation_start,
                                model2: _vm.model.confirmation_end,
                                label: "Período de Confirmação"
                              },
                              on: {
                                "update:model1": function($event) {
                                  return _vm.$set(
                                    _vm.model,
                                    "confirmation_start",
                                    $event
                                  )
                                },
                                "update:model2": function($event) {
                                  return _vm.$set(
                                    _vm.model,
                                    "confirmation_end",
                                    $event
                                  )
                                }
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "3" } },
                          [
                            _c("sys-select-frequency", {
                              attrs: {
                                model: _vm.model.frequency_id,
                                error: _vm.errors.length > 0,
                                errors: _vm.errors.frequency_id,
                                label: "Regime*",
                                dense: "",
                                "text-helper": "Regime da oferta do Período"
                              },
                              on: {
                                "update:model": function($event) {
                                  return _vm.$set(
                                    _vm.model,
                                    "frequency_id",
                                    $event
                                  )
                                }
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "3" } },
                          [
                            _c("sys-select-work-time-category", {
                              attrs: {
                                model: _vm.model.work_time_category_id,
                                error: _vm.errors.length > 0,
                                errors: _vm.errors.work_time_category_id,
                                label: "Categoria",
                                dense: "",
                                showClear: ""
                              },
                              on: {
                                "update:model": function($event) {
                                  return _vm.$set(
                                    _vm.model,
                                    "work_time_category_id",
                                    $event
                                  )
                                }
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "3" } },
                          [
                            _c("s-text-field", {
                              attrs: {
                                "text-helper":
                                  "Número de semanas total do período. O número de semanas é utilizado para conversão da carga horária total para semanal.",
                                "error-messages": _vm.errors.weeks,
                                label: "Semanas"
                              },
                              model: {
                                value: _vm.model.weeks,
                                callback: function($$v) {
                                  _vm.$set(_vm.model, "weeks", $$v)
                                },
                                expression: "model.weeks"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "3" } },
                          [
                            _c("s-text-field", {
                              attrs: {
                                code: "",
                                "error-messages": _vm.errors.code,
                                label: "Código"
                              },
                              model: {
                                value: _vm.model.code,
                                callback: function($$v) {
                                  _vm.$set(_vm.model, "code", $$v)
                                },
                                expression: "model.code"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "3" } },
                          [
                            _c("s-switch", {
                              attrs: {
                                "text-helper":
                                  "Período letivo fechado não permite alterações nos dados.",
                                color: "purple",
                                inset: "",
                                label: "Período Fechado"
                              },
                              model: {
                                value: _vm.model.closed,
                                callback: function($$v) {
                                  _vm.$set(_vm.model, "closed", $$v)
                                },
                                expression: "model.closed"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "3" } },
                          [
                            _c("s-switch", {
                              attrs: {
                                "text-helper":
                                  "Período inativo, apenas para registro.",
                                color: "purple",
                                inset: "",
                                label: "Ativo"
                              },
                              model: {
                                value: _vm.model.active,
                                callback: function($$v) {
                                  _vm.$set(_vm.model, "active", $$v)
                                },
                                expression: "model.active"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "3" } },
                          [
                            _c("s-switch", {
                              attrs: {
                                "text-helper":
                                  "Período padrão exibido na tela de seleção",
                                color: "purple",
                                inset: "",
                                label: "Período Padrão"
                              },
                              model: {
                                value: _vm.model.is_default,
                                callback: function($$v) {
                                  _vm.$set(_vm.model, "is_default", $$v)
                                },
                                expression: "model.is_default"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-tab-item",
                  { key: "dates" },
                  [
                    _c("sys-work-time-register-dates", {
                      attrs: { model: _vm.model, errors: _vm.errors }
                    })
                  ],
                  1
                ),
                _c(
                  "v-tab-item",
                  { key: "integration" },
                  [
                    _c(
                      "v-container",
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "3" } },
                              [
                                _c("emc-calendar-date-picker2", {
                                  attrs: {
                                    errorMessages: _vm.errors.start_integration,
                                    show: _vm.dialog,
                                    model: _vm.model.start_integration,
                                    "open-on-click": false,
                                    readonly: false,
                                    "show-clear": "",
                                    label: "Início"
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "start_integration",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "3" } },
                              [
                                _c("emc-calendar-date-picker2", {
                                  attrs: {
                                    errorMessages: _vm.errors.end_integration,
                                    show: _vm.dialog,
                                    model: _vm.model.end_integration,
                                    "open-on-click": false,
                                    readonly: false,
                                    "show-clear": "",
                                    label: "Fim"
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "end_integration",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "12" } },
                              [
                                _c("SysWorkTimeIntegration", {
                                  attrs: { workTime: _vm.model }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("emc-alert-snackbar", {
              attrs: {
                show: !!_vm.message.length,
                message: _vm.message,
                color: _vm.error ? "error" : "success"
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }