var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.model,
      title: _vm.title,
      icon: "mdi-clipboard-list-outline",
      show: _vm.dialog,
      maxWidth: "60%"
    },
    on: {
      onSave: _vm.save,
      "update:model": function($event) {
        _vm.model = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "tools",
        fn: function() {
          return [
            _c("sys-activity-item-pop-over-time-table", {
              attrs: { model: _vm.config }
            })
          ]
        },
        proxy: true
      },
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-tabs",
              {
                staticClass: "ma-0",
                model: {
                  value: _vm.tab,
                  callback: function($$v) {
                    _vm.tab = $$v
                  },
                  expression: "tab"
                }
              },
              [
                _c("v-tab", { key: "main" }, [_vm._v("Dados Principais")]),
                _c("v-tab", { key: "time_table" }, [_vm._v("Horários")]),
                _vm.isMultiTeacher && !_vm.teacher
                  ? _c("v-tab", { key: "teachers" }, [_vm._v("Professores")])
                  : _vm._e()
              ],
              1
            ),
            _c(
              "v-tabs-items",
              {
                model: {
                  value: _vm.tab,
                  callback: function($$v) {
                    _vm.tab = $$v
                  },
                  expression: "tab"
                }
              },
              [
                _c(
                  "v-tab-item",
                  { key: "main" },
                  [
                    _c(
                      "v-container",
                      [
                        _vm.acesso.instituicao &&
                        !_vm.acesso.instituicao.settings
                          .allow_multi_teachers_activities
                          ? _c(
                              "v-row",
                              { staticClass: "mt-1" },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "11" } },
                                  [
                                    _c("sys-select-teacher", {
                                      attrs: {
                                        model: _vm.model.teacher_id,
                                        error:
                                          _vm.errors.teacher_id &&
                                          _vm.errors.length > 0,
                                        data: _vm.model.teacher,
                                        selectedObject: _vm.teacherSelected,
                                        errors: _vm.errors.teacher_id,
                                        label: "Professor",
                                        autoComplete: "",
                                        autofocus: "",
                                        disabled: _vm.teacher != null,
                                        showClear: "",
                                        dense: ""
                                      },
                                      on: {
                                        "update:model": function($event) {
                                          return _vm.$set(
                                            _vm.model,
                                            "teacher_id",
                                            $event
                                          )
                                        },
                                        "update:data": function($event) {
                                          return _vm.$set(
                                            _vm.model,
                                            "teacher",
                                            $event
                                          )
                                        },
                                        "update:selectedObject": function(
                                          $event
                                        ) {
                                          _vm.teacherSelected = $event
                                        },
                                        "update:selected-object": function(
                                          $event
                                        ) {
                                          _vm.teacherSelected = $event
                                        }
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "1" } },
                                  [
                                    _vm.model && _vm.model.id
                                      ? _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              dense: "",
                                              disabled:
                                                _vm.model &&
                                                _vm.model.teacher_id ===
                                                  _vm.teacher_id_original,
                                              loading: _vm.loadingUpdateTecher,
                                              color: "success",
                                              small: "",
                                              icon: ""
                                            },
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                $event.stopPropagation()
                                                return _vm.updateTeacher()
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: { small: "", dark: "" }
                                              },
                                              [
                                                _vm._v(
                                                  "mdi-content-save-edit-outline"
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "v-row",
                          { staticClass: "mt-1" },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "2" } },
                              [
                                _c("sys-select-activity-item-entry-type", {
                                  attrs: {
                                    model:
                                      _vm.model.activity_item_entry_type_id,
                                    error: _vm.errors.length > 0,
                                    errors:
                                      _vm.errors.activity_item_entry_type_id,
                                    label: "Tipo Lançamento*",
                                    dense: ""
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "activity_item_entry_type_id",
                                        $event
                                      )
                                    },
                                    change: function($event) {
                                      return _vm.changeEntryType($event)
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _vm.renderComponent
                              ? _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "7" } },
                                  [
                                    _vm.model.activity_item_entry_type_id == 1
                                      ? _c("sys-select-campus", {
                                          attrs: {
                                            model: _vm.model.campus_id,
                                            error: _vm.errors.length > 0,
                                            errors: _vm.errors.campus_id,
                                            label: "Campus*",
                                            dense: "",
                                            disabled: _vm.restrict,
                                            "auto-complete": ""
                                          },
                                          on: {
                                            "update:model": function($event) {
                                              return _vm.$set(
                                                _vm.model,
                                                "campus_id",
                                                $event
                                              )
                                            },
                                            change: function($event) {
                                              return _vm.changeCampus($event)
                                            }
                                          }
                                        })
                                      : _vm.model.activity_item_entry_type_id ==
                                        2
                                      ? _c("sys-select-departament", {
                                          attrs: {
                                            model: _vm.model.departament_id,
                                            error: _vm.errors.length > 0,
                                            errors: _vm.errors.departament_id,
                                            label: "Departamento*",
                                            dense: "",
                                            disabled: _vm.restrict,
                                            "auto-complete": ""
                                          },
                                          on: {
                                            "update:model": function($event) {
                                              return _vm.$set(
                                                _vm.model,
                                                "departament_id",
                                                $event
                                              )
                                            },
                                            change: function($event) {
                                              return _vm.changeDapartament(
                                                $event
                                              )
                                            }
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "3" } },
                              [
                                _vm.renderComponentBusinessUnit
                                  ? _c("s-text-field", {
                                      attrs: {
                                        label: "Unidade Negócio",
                                        dense: "",
                                        disabled:
                                          _vm.restrict ||
                                          _vm.disabledBusinessUnit,
                                        "error-messages":
                                          _vm.errors.business_unit
                                      },
                                      model: {
                                        value: _vm.model.business_unit,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.model,
                                            "business_unit",
                                            $$v
                                          )
                                        },
                                        expression: "model.business_unit"
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "9" } },
                              [
                                _c("sys-select-course", {
                                  attrs: {
                                    model: _vm.model.courses,
                                    error: _vm.errors.length > 0,
                                    errors: _vm.errors.course_id,
                                    "campus-id": _vm.model.campus_id,
                                    filter: "",
                                    label: "Cursos",
                                    multiple: "",
                                    onlyActive: "",
                                    dense: "",
                                    disabled:
                                      _vm.restrict ||
                                      !_vm.model.campus_id ||
                                      _vm.model.activity_item_entry_type_id == 2
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "courses",
                                        $event
                                      )
                                    },
                                    input: function($event) {
                                      return _vm.changeCourse($event)
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "3" } },
                              [
                                _vm.renderComponentCostCenter
                                  ? _c("sys-select-cost-center", {
                                      attrs: {
                                        model: _vm.model.cost_center_id,
                                        error: _vm.errors.length > 0,
                                        errors: _vm.errors.cost_center_id,
                                        label: "Centro Custo",
                                        disabled:
                                          _vm.restrict ||
                                          _vm.disabledCostCenter,
                                        showClear: "",
                                        "auto-complete": "",
                                        dense: "",
                                        "filter-id": _vm.costCenterId
                                      },
                                      on: {
                                        "update:model": function($event) {
                                          return _vm.$set(
                                            _vm.model,
                                            "cost_center_id",
                                            $event
                                          )
                                        }
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "9" } },
                              [
                                _c("sys-select-activity", {
                                  attrs: {
                                    model: _vm.model.activity_id,
                                    error: _vm.errors.length > 0,
                                    errors: _vm.errors.activity_id,
                                    label: "Atividade*",
                                    itemText: "name_complete",
                                    disabled: _vm.restrict,
                                    dense: "",
                                    autoComplete: "",
                                    "brand-id": _vm.selectedCampus
                                      ? _vm.selectedCampus.brand_id
                                      : null
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "activity_id",
                                        $event
                                      )
                                    },
                                    change: function($event) {
                                      return _vm.changeActivity($event)
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "3" } },
                              [
                                _c("sys-select-payment-event", {
                                  attrs: {
                                    model: _vm.model.payment_event_id,
                                    error: _vm.errors.length > 0,
                                    errors: _vm.errors.payment_event_id,
                                    label: "Evento Pgto",
                                    "item-text": "initials",
                                    showClear: "",
                                    "auto-complete": "",
                                    dense: "",
                                    disabled: _vm.disabledPaymentEvent
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "payment_event_id",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "2" } },
                              [
                                _c("sys-select-payment-periodicity-type", {
                                  attrs: {
                                    model:
                                      _vm.model.payment_periodicity_type_id,
                                    error: _vm.errors.length > 0,
                                    errors:
                                      _vm.errors.payment_periodicity_type_id,
                                    label: "Periodicidade*",
                                    dense: "",
                                    disabled:
                                      _vm.restrict ||
                                      (_vm.selectedActivity != null &&
                                        _vm.selectedActivity.paymentEvent !=
                                          null &&
                                        _vm.selectedActivity.paymentEvent
                                          .payment_periodicity_type_id != null)
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "payment_periodicity_type_id",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "3" } },
                              [
                                _c("sys-select-value-type", {
                                  attrs: {
                                    model: _vm.model.value_type_id,
                                    error: _vm.errors.length > 0,
                                    errors: _vm.errors.value_type_id,
                                    label: "Tipo de Valor*",
                                    dense: "",
                                    disabled:
                                      _vm.restrict ||
                                      (_vm.selectedActivity &&
                                        _vm.selectedActivity.paymentEvent &&
                                        _vm.selectedActivity.paymentEvent
                                          .value_type_id)
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "value_type_id",
                                        $event
                                      )
                                    },
                                    change: function($event) {
                                      return _vm.forceRerenderValue()
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "3" } },
                              [
                                _vm.renderComponentValue
                                  ? _c("s-text-field", {
                                      attrs: {
                                        dense: "",
                                        "error-messages": _vm.errors.value,
                                        label: _vm.labelValue,
                                        disabled: _vm.restrict,
                                        "text-helper":
                                          "Utilizar '.' (ponto) como separador decimal"
                                      },
                                      model: {
                                        value: _vm.model.value,
                                        callback: function($$v) {
                                          _vm.$set(_vm.model, "value", $$v)
                                        },
                                        expression: "model.value"
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "2" } },
                              [
                                _c("emc-calendar-date-picker2", {
                                  attrs: {
                                    model: _vm.model.date_start,
                                    label: "Início*",
                                    errorMessages: _vm.errors.date_start,
                                    show: _vm.dialog,
                                    disabled: _vm.restrict,
                                    min: _vm.acesso.periodo.start,
                                    max: _vm.acesso.periodo.end,
                                    dense: ""
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "date_start",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "2" } },
                              [
                                _c("emc-calendar-date-picker2", {
                                  attrs: {
                                    model: _vm.model.date_end,
                                    label: "Fim*",
                                    errorMessages: _vm.errors.date_end,
                                    show: _vm.dialog,
                                    disabled: _vm.restrict,
                                    min: _vm.acesso.periodo.start,
                                    max: _vm.acesso.periodo.end,
                                    dense: ""
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "date_end",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "12" } },
                              [
                                _c("v-textarea", {
                                  attrs: {
                                    error: !!_vm.errors.note,
                                    "error-messages": _vm.errors.note,
                                    label: "Observação",
                                    outlined: "",
                                    clearable: "",
                                    rows: "3"
                                  },
                                  model: {
                                    value: _vm.model.note,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "note", $$v)
                                    },
                                    expression: "model.note"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "4" } },
                              [
                                _c("s-switch", {
                                  attrs: {
                                    "text-helper":
                                      "Atividade não remunerada para docentes com cargo configurado para Regime TI/TP",
                                    inset: "",
                                    label: "Não remunerar TI/TP"
                                  },
                                  model: {
                                    value: _vm.model.not_pay_work_regime,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.model,
                                        "not_pay_work_regime",
                                        $$v
                                      )
                                    },
                                    expression: "model.not_pay_work_regime"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "3" } },
                              [
                                _c("s-text-field", {
                                  attrs: {
                                    disabled: _vm.restrict,
                                    "error-messages": _vm.errors.students,
                                    label: "Alunos"
                                  },
                                  model: {
                                    value: _vm.model.students,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "students", $$v)
                                    },
                                    expression: "model.students"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "3" } },
                              [
                                _c("s-text-field", {
                                  attrs: {
                                    disabled: _vm.restrict,
                                    code: "",
                                    "error-messages": _vm.errors.code,
                                    label: "Código"
                                  },
                                  model: {
                                    value: _vm.model.code,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "code", $$v)
                                    },
                                    expression: "model.code"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-tab-item",
                  { key: "time_table" },
                  [
                    _c("sys-activity-item-register-time-table", {
                      attrs: { model: _vm.model, errors: _vm.errors }
                    })
                  ],
                  1
                ),
                _vm.acesso.instituicao &&
                _vm.acesso.instituicao.settings
                  .allow_multi_teachers_activities &&
                !_vm.teacher
                  ? _c(
                      "v-tab-item",
                      { key: "teachers" },
                      [
                        _c("sys-activity-item-register-teachers", {
                          attrs: {
                            "work-time": _vm.acesso.periodo,
                            model: _vm.model,
                            errors: _vm.errors
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            ),
            _c("emc-alert-snackbar", {
              attrs: {
                show: !!_vm.message.length,
                message: _vm.message,
                color: _vm.error ? "error" : "success"
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }