var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      !_vm.imageError && _vm.src
        ? _c(
            "v-avatar",
            {
              class: _vm.strClass,
              staticStyle: { cursor: "pointer" },
              attrs: { color: _vm.color, size: _vm.size },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  $event.stopPropagation()
                  return _vm.$emit("click")
                }
              }
            },
            [
              _c("v-img", {
                attrs: { src: _vm.src, alt: _vm.text },
                on: {
                  error: function($event) {
                    return _vm.onImgError()
                  }
                }
              })
            ],
            1
          )
        : _c(
            "v-avatar",
            {
              class: _vm.strClass,
              attrs: { color: _vm.color, size: _vm.size }
            },
            [
              _c("span", { class: _vm.textColor }, [
                _vm._v(_vm._s(_vm.text) + " ")
              ])
            ]
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }