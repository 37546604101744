var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.model,
      title: _vm.title,
      icon: _vm.icon,
      show: _vm.dialog,
      maxWidth: "70%"
    },
    on: {
      onSave: _vm.save,
      "update:model": function($event) {
        _vm.model = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-container",
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "7" } },
                      [
                        _c("sys-select-teacher", {
                          attrs: {
                            model: _vm.model.teacher_id,
                            error:
                              _vm.errors.teacher_id && _vm.errors.length > 0,
                            data: _vm.model.teacher,
                            selectedObject: _vm.teacherSelected,
                            errors: _vm.errors.teacher_id,
                            label: "Professor*",
                            autoComplete: "",
                            autofocus: "",
                            disabled: _vm.disabled || _vm.teacher != null
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(_vm.model, "teacher_id", $event)
                            },
                            "update:data": function($event) {
                              return _vm.$set(_vm.model, "teacher", $event)
                            },
                            "update:selectedObject": function($event) {
                              _vm.teacherSelected = $event
                            },
                            "update:selected-object": function($event) {
                              _vm.teacherSelected = $event
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "5" } },
                      [
                        _c("sys-select-teacher-contract", {
                          attrs: {
                            model: _vm.model.teacher_contract_id,
                            error:
                              _vm.errors.teacher_contract_id &&
                              _vm.errors.length > 0,
                            errors: _vm.errors.teacher_contract_id,
                            label: "Matrícula",
                            autoComplete: "",
                            autofocus: "",
                            "filter-company": "",
                            showClear: "",
                            disabled: _vm.disabled || !_vm.model.teacher_id,
                            "teacher-id": _vm.model.teacher_id
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(
                                _vm.model,
                                "teacher_contract_id",
                                $event
                              )
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "5" } },
                      [
                        _c("sys-select-campus", {
                          attrs: {
                            model: _vm.model.campus_id,
                            label: "Campus",
                            dense: "",
                            "auto-complete": "",
                            "show-clear": "",
                            disabled: _vm.disabled
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(_vm.model, "campus_id", $event)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _c("sys-select-course", {
                          attrs: {
                            model: _vm.model.course_id,
                            label: "Curso",
                            dense: "",
                            "auto-complete": "",
                            disabled: _vm.disabled || !_vm.model.campus_id,
                            "campus-id": _vm.model.campus_id,
                            "show-clear": ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(_vm.model, "course_id", $event)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "3" } },
                      [
                        _c("sys-select-group", {
                          attrs: {
                            model: _vm.model.group_id,
                            label: "Turma",
                            dense: "",
                            "auto-complete": "",
                            "show-clear": "",
                            disabled: _vm.disabled || !_vm.model.course_id,
                            "campus-id": _vm.model.campus_id,
                            "course-id": _vm.model.course_id
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(_vm.model, "group_id", $event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.paymentExport.payment_type_id == 2
                  ? _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "12" } },
                          [
                            _c("sys-select-activity", {
                              attrs: {
                                model: _vm.model.activity_id,
                                error: _vm.errors.length > 0,
                                errors: _vm.errors.activity_id,
                                label: "Atividade",
                                itemText: "name_complete",
                                autoComplete: "",
                                disabled: _vm.disabled,
                                dense: ""
                              },
                              on: {
                                "update:model": function($event) {
                                  return _vm.$set(
                                    _vm.model,
                                    "activity_id",
                                    $event
                                  )
                                }
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _c("sys-select-payment-periodicity-type", {
                          attrs: {
                            model: _vm.model.payment_periodicity_type_id,
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.payment_periodicity_type_id,
                            label: "Periodicidade*",
                            disabled: _vm.disabled,
                            dense: ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(
                                _vm.model,
                                "payment_periodicity_type_id",
                                $event
                              )
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _c("sys-select-value-type", {
                          attrs: {
                            model: _vm.model.value_type_id,
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.value_type_id,
                            label: "Tipo de Valor*",
                            dense: "",
                            disabled: _vm.disabled
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(
                                _vm.model,
                                "value_type_id",
                                $event
                              )
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _vm.paymentExport.payment_export_prorated_type_id == 1
                          ? _c("s-text-field", {
                              attrs: {
                                "error-messages":
                                  _vm.errors.value_prorated_grids,
                                label: "Valor*",
                                dense: "",
                                disabled: _vm.disabled
                              },
                              model: {
                                value: _vm.model.value_prorated_grids,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.model,
                                    "value_prorated_grids",
                                    $$v
                                  )
                                },
                                expression: "model.value_prorated_grids"
                              }
                            })
                          : _vm.paymentExport.payment_export_prorated_type_id ==
                            2
                          ? _c("s-text-field", {
                              attrs: {
                                "error-messages":
                                  _vm.errors.value_prorated_students,
                                label: "Valor*",
                                dense: "",
                                disabled: _vm.disabled
                              },
                              model: {
                                value: _vm.model.value_prorated_students,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.model,
                                    "value_prorated_students",
                                    $$v
                                  )
                                },
                                expression: "model.value_prorated_students"
                              }
                            })
                          : _c("s-text-field", {
                              attrs: {
                                "error-messages": _vm.errors.value,
                                label: "Valor*",
                                dense: "",
                                disabled: _vm.disabled
                              },
                              model: {
                                value: _vm.model.value,
                                callback: function($$v) {
                                  _vm.$set(_vm.model, "value", $$v)
                                },
                                expression: "model.value"
                              }
                            })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "3" } },
                      [
                        _c("emc-calendar-date-picker2", {
                          attrs: {
                            model: _vm.model.start,
                            label: "Início*",
                            errorMessages: _vm.errors.start,
                            show: _vm.dialog,
                            min: _vm.acesso.periodo.start,
                            max: _vm.acesso.periodo.end,
                            disabled: _vm.disabled,
                            dense: ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(_vm.model, "start", $event)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "3" } },
                      [
                        _c("emc-calendar-date-picker2", {
                          attrs: {
                            model: _vm.model.end,
                            label: "Fim*",
                            errorMessages: _vm.errors.end,
                            show: _vm.dialog,
                            min: _vm.acesso.periodo.start,
                            max: _vm.acesso.periodo.end,
                            disabled: _vm.disabled,
                            dense: ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(_vm.model, "end", $event)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "3" } },
                      [
                        _c("sys-select-cost-center", {
                          attrs: {
                            model: _vm.model.cost_center_id,
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.cost_center_id,
                            label: "Centro de Custo",
                            showClear: "",
                            "auto-complete": "",
                            dense: "",
                            disabled: _vm.disabled || !!_vm.model.course_id
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(
                                _vm.model,
                                "cost_center_id",
                                $event
                              )
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "3" } },
                      [
                        _c("sys-select-payment-event", {
                          attrs: {
                            model: _vm.model.payment_event_id,
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.payment_event_id,
                            label: "Evento de Pgto",
                            "item-text": "initials",
                            showClear: "",
                            "auto-complete": "",
                            dense: "",
                            disabled: _vm.disabled
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(
                                _vm.model,
                                "payment_event_id",
                                $event
                              )
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "12" } },
                      [
                        _c("v-textarea", {
                          attrs: {
                            error: !!_vm.errors.note,
                            "error-messages": _vm.errors.note,
                            label: "Observação",
                            counter: 255,
                            rules: [
                              function(v) {
                                return (
                                  !v ||
                                  v.length <= 255 ||
                                  "Máximo de 255 caracteres atingido"
                                )
                              }
                            ],
                            outlined: "",
                            disabled: _vm.disabled,
                            rows: "2"
                          },
                          model: {
                            value: _vm.model.note,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "note", $$v)
                            },
                            expression: "model.note"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.model.details
                  ? _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "12" } },
                          [
                            _c("v-textarea", {
                              attrs: {
                                error: !!_vm.errors.details,
                                "error-messages": _vm.errors.details,
                                label: "Detalhes",
                                counter: 255,
                                rules: [
                                  function(v) {
                                    return (
                                      !v ||
                                      v.length <= 255 ||
                                      "Máximo de 255 caracteres atingido"
                                    )
                                  }
                                ],
                                outlined: "",
                                disabled: "",
                                rows: "2"
                              },
                              model: {
                                value: _vm.model.details,
                                callback: function($$v) {
                                  _vm.$set(_vm.model, "details", $$v)
                                },
                                expression: "model.details"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            ),
            _c("emc-alert-snackbar", {
              attrs: {
                show: !!_vm.message.length,
                message: _vm.message,
                color: _vm.error ? "error" : "success"
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }