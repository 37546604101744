var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.renderComponent
        ? _c("emc-calendar-date-picker", {
            attrs: {
              model: _vm.innerModel,
              label: _vm.label,
              icon: _vm.icon,
              show: _vm.show,
              errorMessages: _vm.errorMessages,
              disabled: _vm.disabled,
              fieldClass: _vm.fieldClass,
              small: _vm.small,
              dense: _vm.dense,
              min: _vm.min,
              max: _vm.max,
              showClear: _vm.showClear,
              readonly: _vm.readonly,
              openOnClick: _vm.openOnClick,
              textHelper: _vm.textHelper,
              outlined: _vm.outlined
            },
            on: {
              "update:model": function($event) {
                _vm.innerModel = $event
              },
              change: function($event) {
                return _vm.changeValue($event)
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }