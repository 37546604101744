var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.saving,
      model: _vm.model,
      title: _vm.title,
      icon: _vm.icon,
      show: _vm.dialog,
      maxWidth: "70%"
    },
    on: {
      onSave: _vm.save,
      "update:model": function($event) {
        _vm.model = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function() {
          return [
            _vm.loading
              ? _c("v-progress-linear", {
                  attrs: { indeterminate: "", color: "yellow darken-2" }
                })
              : _vm._e(),
            _c(
              "v-tabs",
              {
                staticClass: "ma-0",
                model: {
                  value: _vm.tab,
                  callback: function($$v) {
                    _vm.tab = $$v
                  },
                  expression: "tab"
                }
              },
              [
                _c("v-tab", { key: "main" }, [_vm._v("Textos")]),
                _c("v-tab", { key: "show" }, [_vm._v("Exibição")])
              ],
              1
            ),
            _c(
              "v-tabs-items",
              {
                model: {
                  value: _vm.tab,
                  callback: function($$v) {
                    _vm.tab = $$v
                  },
                  expression: "tab"
                }
              },
              [
                _c(
                  "v-tab-item",
                  { key: "main" },
                  [
                    _vm.model
                      ? _c(
                          "v-container",
                          [
                            _vm.acesso.instituicao.id == 1
                              ? _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", sm: "12" } },
                                      [
                                        _c(
                                          "v-alert",
                                          {
                                            attrs: {
                                              dense: "",
                                              border: "left",
                                              type: "warning"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " As alterações realizadas serão consideradas "
                                            ),
                                            _c("strong", [_vm._v("PADRÃO")]),
                                            _vm._v(" para as "),
                                            _c("strong", [
                                              _vm._v("DEMAIS INSTITUIÇÕES")
                                            ]),
                                            _vm._v(". ")
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "12" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        autofocus: "",
                                        error: !!_vm.errors.title,
                                        "error-messages": _vm.errors.title,
                                        label: "Título",
                                        clearable: "",
                                        "persistent-placeholder": "",
                                        placeholder: _vm.model.default.title
                                      },
                                      model: {
                                        value: _vm.model.title,
                                        callback: function($$v) {
                                          _vm.$set(_vm.model, "title", $$v)
                                        },
                                        expression: "model.title"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "12" } },
                                  [
                                    _c("v-textarea", {
                                      attrs: {
                                        "error-messages": _vm.errors.header,
                                        label: "Cabeçalho",
                                        outlined: "",
                                        clearable: ""
                                      },
                                      model: {
                                        value: _vm.model.header,
                                        callback: function($$v) {
                                          _vm.$set(_vm.model, "header", $$v)
                                        },
                                        expression: "model.header"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "6" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        error: !!_vm.errors.caption_availablity,
                                        "error-messages":
                                          _vm.errors.caption_availablity,
                                        clearable: "",
                                        label:
                                          "Cabeçalho do quadro Disponibilidade",
                                        "persistent-placeholder": "",
                                        placeholder:
                                          _vm.model.default.caption_availablity
                                      },
                                      model: {
                                        value: _vm.model.caption_availablity,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.model,
                                            "caption_availablity",
                                            $$v
                                          )
                                        },
                                        expression: "model.caption_availablity"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "6" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        error: !!_vm.errors
                                          .caption_attributions,
                                        "error-messages":
                                          _vm.errors.caption_attributions,
                                        clearable: "",
                                        label:
                                          "Cabeçalho do quadro Atribuições",
                                        "persistent-placeholder": "",
                                        placeholder:
                                          _vm.model.default.caption_attributions
                                      },
                                      model: {
                                        value: _vm.model.caption_attributions,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.model,
                                            "caption_attributions",
                                            $$v
                                          )
                                        },
                                        expression: "model.caption_attributions"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "6" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        error: !!_vm.errors
                                          .caption_class_time_tables,
                                        "error-messages":
                                          _vm.errors.caption_class_time_tables,
                                        clearable: "",
                                        label:
                                          "Cabeçalho do quadro Horário das Aulas",
                                        "persistent-placeholder": "",
                                        placeholder:
                                          _vm.model.default
                                            .caption_class_time_tables
                                      },
                                      model: {
                                        value:
                                          _vm.model.caption_class_time_tables,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.model,
                                            "caption_class_time_tables",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "model.caption_class_time_tables"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "6" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        error: !!_vm.errors.caption_activities,
                                        "error-messages":
                                          _vm.errors.caption_activities,
                                        clearable: "",
                                        label: "Cabeçalho do quadro Atividades",
                                        "persistent-placeholder": "",
                                        placeholder:
                                          _vm.model.default.caption_activities
                                      },
                                      model: {
                                        value: _vm.model.caption_activities,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.model,
                                            "caption_activities",
                                            $$v
                                          )
                                        },
                                        expression: "model.caption_activities"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "4" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        error: !!_vm.errors
                                          .caption_activity_time_tables,
                                        "error-messages":
                                          _vm.errors
                                            .caption_activity_time_tables,
                                        clearable: "",
                                        label:
                                          "Cabeçalho do quadro Horário das Atividades",
                                        "persistent-placeholder": "",
                                        placeholder:
                                          _vm.model.default
                                            .caption_activity_time_tables
                                      },
                                      model: {
                                        value:
                                          _vm.model
                                            .caption_activity_time_tables,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.model,
                                            "caption_activity_time_tables",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "model.caption_activity_time_tables"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "4" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        error: !!_vm.errors.caption_summary,
                                        "error-messages":
                                          _vm.errors.caption_summary,
                                        clearable: "",
                                        label: "Cabeçalho do Quadro Resumo",
                                        "persistent-placeholder": "",
                                        placeholder:
                                          _vm.model.default.caption_summary
                                      },
                                      model: {
                                        value: _vm.model.caption_summary,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.model,
                                            "caption_summary",
                                            $$v
                                          )
                                        },
                                        expression: "model.caption_summary"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "4" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        error: !!_vm.errors.caption_joins,
                                        "error-messages":
                                          _vm.errors.caption_joins,
                                        clearable: "",
                                        label: "Cabeçalho do Quadro de Junção",
                                        "persistent-placeholder": "",
                                        placeholder:
                                          _vm.model.default.caption_joins
                                      },
                                      model: {
                                        value: _vm.model.caption_joins,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.model,
                                            "caption_joins",
                                            $$v
                                          )
                                        },
                                        expression: "model.caption_joins"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "12" } },
                                  [
                                    _c("v-textarea", {
                                      attrs: {
                                        "error-messages": _vm.errors.footer,
                                        label: "Rodapé",
                                        outlined: "",
                                        clearable: ""
                                      },
                                      model: {
                                        value: _vm.model.footer,
                                        callback: function($$v) {
                                          _vm.$set(_vm.model, "footer", $$v)
                                        },
                                        expression: "model.footer"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "v-tab-item",
                  { key: "show" },
                  [
                    _vm.model
                      ? _c(
                          "v-container",
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "3" } },
                                  [
                                    _c("s-switch", {
                                      attrs: {
                                        "text-helper":
                                          "Exibir quadro resumo da Carga Horária",
                                        inset: "",
                                        label: "Quadro Resumo"
                                      },
                                      model: {
                                        value: _vm.model.show_summary,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.model,
                                            "show_summary",
                                            $$v
                                          )
                                        },
                                        expression: "model.show_summary"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "3" } },
                                  [
                                    _c("s-switch", {
                                      attrs: {
                                        "text-helper":
                                          "Exibir quadro de Disponibilidade",
                                        inset: "",
                                        label: "Disponibilidade"
                                      },
                                      model: {
                                        value: _vm.model.show_availability,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.model,
                                            "show_availability",
                                            $$v
                                          )
                                        },
                                        expression: "model.show_availability"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "3" } },
                                  [
                                    _c("s-switch", {
                                      attrs: {
                                        "text-helper":
                                          "Exibir quadro de Atribuição de aulas",
                                        inset: "",
                                        label: "Atribuição"
                                      },
                                      model: {
                                        value: _vm.model.show_attributions,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.model,
                                            "show_attributions",
                                            $$v
                                          )
                                        },
                                        expression: "model.show_attributions"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "3" } },
                                  [
                                    _c("s-switch", {
                                      attrs: {
                                        "text-helper":
                                          "Exibir quadro de Horário de Aula",
                                        inset: "",
                                        label: "Horário de Aulas"
                                      },
                                      model: {
                                        value: _vm.model.show_class_time_tables,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.model,
                                            "show_class_time_tables",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "model.show_class_time_tables"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "3" } },
                                  [
                                    _c("s-switch", {
                                      attrs: {
                                        "text-helper":
                                          "Exibir quadro de Atividades",
                                        inset: "",
                                        label: "Atividades"
                                      },
                                      model: {
                                        value: _vm.model.show_activities,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.model,
                                            "show_activities",
                                            $$v
                                          )
                                        },
                                        expression: "model.show_activities"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "3" } },
                                  [
                                    _c("s-switch", {
                                      attrs: {
                                        "text-helper":
                                          "Exibir quadro de Horário de Atividades",
                                        inset: "",
                                        label: "Horário das Atividades"
                                      },
                                      model: {
                                        value:
                                          _vm.model.show_activity_time_tables,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.model,
                                            "show_activity_time_tables",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "model.show_activity_time_tables"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "3" } },
                                  [
                                    _c("s-switch", {
                                      attrs: {
                                        "text-helper":
                                          "Exibir campo de assinatura do professor",
                                        inset: "",
                                        label: "Assinatura"
                                      },
                                      model: {
                                        value: _vm.model.show_teacher_signature,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.model,
                                            "show_teacher_signature",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "model.show_teacher_signature"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "3" } },
                                  [
                                    _c("s-switch", {
                                      attrs: {
                                        "text-helper":
                                          "Exibir município da Instituição junto com a data no rodapé do documento",
                                        inset: "",
                                        label: "Município"
                                      },
                                      model: {
                                        value: _vm.model.show_footer_city_date,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.model,
                                            "show_footer_city_date",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "model.show_footer_city_date"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "3" } },
                                  [
                                    _c("s-switch", {
                                      attrs: {
                                        "text-helper": "Exibir logo da empresa",
                                        inset: "",
                                        label: "Logo da empresa"
                                      },
                                      model: {
                                        value: _vm.model.show_company_logo,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.model,
                                            "show_company_logo",
                                            $$v
                                          )
                                        },
                                        expression: "model.show_company_logo"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "3" } },
                                  [
                                    _c("s-switch", {
                                      attrs: {
                                        "text-helper":
                                          "Exibir foto do professor",
                                        inset: "",
                                        label: "Foto do Professor"
                                      },
                                      model: {
                                        value: _vm.model.show_avatar,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.model,
                                            "show_avatar",
                                            $$v
                                          )
                                        },
                                        expression: "model.show_avatar"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "3" } },
                                  [
                                    _c("s-switch", {
                                      attrs: {
                                        "text-helper":
                                          "Exibir as turmas da junção",
                                        inset: "",
                                        label: "Exibir Junções"
                                      },
                                      model: {
                                        value: _vm.model.show_joins,
                                        callback: function($$v) {
                                          _vm.$set(_vm.model, "show_joins", $$v)
                                        },
                                        expression: "model.show_joins"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "3" } },
                                  [
                                    _c("s-switch", {
                                      attrs: {
                                        "text-helper":
                                          "Agrupar horários das aulas com início e fim",
                                        inset: "",
                                        label: "Agrupar Horários"
                                      },
                                      model: {
                                        value: _vm.model.group_schedules,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.model,
                                            "group_schedules",
                                            $$v
                                          )
                                        },
                                        expression: "model.group_schedules"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "3" } },
                                  [
                                    _c("s-switch", {
                                      attrs: {
                                        "text-helper":
                                          "Exibir confirmação de carga horária",
                                        inset: "",
                                        label: "Confirmação de CH"
                                      },
                                      model: {
                                        value:
                                          _vm.model.show_workload_confirmation,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.model,
                                            "show_workload_confirmation",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "model.show_workload_confirmation"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "3" } },
                                  [
                                    _c("s-switch", {
                                      attrs: {
                                        "text-helper":
                                          "Exibir Cnpj no título do extrato",
                                        inset: "",
                                        label: "CNPJ"
                                      },
                                      model: {
                                        value: _vm.model.show_cnpj,
                                        callback: function($$v) {
                                          _vm.$set(_vm.model, "show_cnpj", $$v)
                                        },
                                        expression: "model.show_cnpj"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "3" } },
                                  [
                                    _c("s-switch", {
                                      attrs: {
                                        "text-helper":
                                          "Exibir cargo do professor",
                                        inset: "",
                                        label: "Cargo"
                                      },
                                      model: {
                                        value: _vm.model.show_position,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.model,
                                            "show_position",
                                            $$v
                                          )
                                        },
                                        expression: "model.show_position"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "3" } },
                                  [
                                    _c("s-switch", {
                                      attrs: {
                                        "text-helper":
                                          "Exibir Regime de Trabalho do professor",
                                        inset: "",
                                        label: "Regime de Trabalho"
                                      },
                                      model: {
                                        value: _vm.model.show_work_regime,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.model,
                                            "show_work_regime",
                                            $$v
                                          )
                                        },
                                        expression: "model.show_work_regime"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "3" } },
                                  [
                                    _c("s-switch", {
                                      attrs: {
                                        "text-helper":
                                          "Exibir titulação do professor",
                                        inset: "",
                                        label: "Titulação"
                                      },
                                      model: {
                                        value: _vm.model.show_titling,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.model,
                                            "show_titling",
                                            $$v
                                          )
                                        },
                                        expression: "model.show_titling"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "3" } },
                                  [
                                    _c("s-switch", {
                                      attrs: {
                                        "text-helper":
                                          "Exibir tipo da disciplina no quadro de atribuições",
                                        inset: "",
                                        label: "Tipo Disciplina"
                                      },
                                      model: {
                                        value: _vm.model.show_discipline_type,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.model,
                                            "show_discipline_type",
                                            $$v
                                          )
                                        },
                                        expression: "model.show_discipline_type"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            ),
            _c("emc-alert-snackbar", {
              attrs: {
                show: !!_vm.message.length,
                message: _vm.message,
                color: _vm.error ? "error" : "success"
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }