var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("sys-register-base", {
        attrs: {
          model: {},
          title: "Replicar Atribuição",
          icon: _vm.icon,
          show: _vm.dialog,
          maxWidth: "40%",
          showRequiredFields: false,
          showButton: false
        },
        on: {
          "update:show": function($event) {
            _vm.dialog = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "form",
            fn: function() {
              return [
                _c(
                  "v-container",
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "12" } },
                          [
                            _c("sys-select-course", {
                              attrs: {
                                model: _vm.model.courses,
                                error: _vm.errors.length > 0,
                                errors: _vm.errors.courses,
                                label: "Curso*",
                                showClear: "",
                                dense: "",
                                multiple: "",
                                autoComplete: "",
                                outlined: ""
                              },
                              on: {
                                "update:model": function($event) {
                                  return _vm.$set(_vm.model, "courses", $event)
                                }
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12", md: "12" } },
                          [
                            _c("sys-select-group", {
                              attrs: {
                                model: _vm.model.groups,
                                "course-id": _vm.model.courses
                                  ? _vm.model.courses
                                      .map(function(c) {
                                        return c.id
                                      })
                                      .join(",")
                                  : "",
                                error:
                                  _vm.errors.groups && _vm.errors.length > 0,
                                errors: _vm.errors.groups,
                                label: "Turma",
                                disabled:
                                  !_vm.model.courses ||
                                  _vm.model.courses.length == 0,
                                outlined: "",
                                multiple: "",
                                dense: ""
                              },
                              on: {
                                "update:model": function($event) {
                                  return _vm.$set(_vm.model, "groups", $event)
                                }
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12", md: "12" } },
                          [
                            _c("SysSelectWorkTime", {
                              attrs: {
                                model: _vm.workTime,
                                label: "Período Letívo de Origem*",
                                "filter-active": "",
                                error: _vm.errors.length > 0,
                                errors: _vm.errors.work_time_id_origin,
                                outlined: "",
                                "return-object": ""
                              },
                              on: {
                                "update:model": function($event) {
                                  _vm.workTime = $event
                                }
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "actions",
            fn: function() {
              return [
                _c("emc-button-icon", {
                  attrs: {
                    icon: _vm.icon,
                    text: "Replicar Atribuição",
                    color: "light-green"
                  },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      $event.stopPropagation()
                      _vm.showMessageReplicate = true
                    }
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("emc-alert-modal", {
        attrs: {
          text:
            "Essa ação irá limpar todas as atribuição do período atual dos cursos selecionados. Deseja replicar a atribuição?",
          show: _vm.showMessageReplicate
        },
        on: {
          "update:show": function($event) {
            _vm.showMessageReplicate = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: { color: "light-green", loading: _vm.loading },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.replicate()
                      }
                    }
                  },
                  [
                    _vm._v("Replicar "),
                    _c("v-icon", { attrs: { right: "", dark: "" } }, [
                      _vm._v(_vm._s(_vm.icon))
                    ])
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }