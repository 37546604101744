var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.model,
      title: _vm.title,
      icon: "mdi-book-open-page-variant",
      show: _vm.dialog,
      maxWidth: "40%"
    },
    on: {
      onSave: _vm.save,
      "update:model": function($event) {
        _vm.model = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "tools",
        fn: function() {
          return [
            _c("sys-find-grid", {
              attrs: { show: _vm.showSelectGrid },
              on: {
                "update:show": function($event) {
                  _vm.showSelectGrid = $event
                },
                onSelect: function($event) {
                  _vm.model.grid = $event[0]
                }
              }
            })
          ]
        },
        proxy: true
      },
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-container",
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "12" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            error: !!_vm.errors.teacher_id,
                            "error-messages": _vm.errors.teacher_id,
                            label: "Professor",
                            value:
                              _vm.model && _vm.model.teacher
                                ? _vm.model.teacher.name
                                : _vm.model.grid && _vm.model.grid.teacher
                                ? _vm.model.grid.teacher.name
                                : null
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "append",
                              fn: function() {
                                return [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { icon: "" },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          _vm.showSelectGrid = true
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "indigo" } },
                                        [_vm._v("mdi-magnify")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ])
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "8" } },
                      [
                        _c("sys-select-occurrence-type", {
                          attrs: {
                            model: _vm.model.occurrence_type_id,
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.occurrence_type_id,
                            label: "Tipo de Ocorrência",
                            showAdd: ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(
                                _vm.model,
                                "occurrence_type_id",
                                $event
                              )
                            },
                            change: _vm.changeType
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            error: _vm.errors.length > 0,
                            "error-messages": _vm.errors.value,
                            label: "Valor*"
                          },
                          model: {
                            value: _vm.model.value,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "value", $$v)
                            },
                            expression: "model.value"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "3" } },
                      [
                        _c("emc-calendar-date-picker2", {
                          attrs: {
                            model: _vm.model.date_start,
                            label: "Data Início*",
                            errorMessages: _vm.errors.date_start,
                            show: _vm.dialog
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(_vm.model, "date_start", $event)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "3" } },
                      [
                        _c("emc-calendar-date-picker2", {
                          attrs: {
                            model: _vm.model.date_end,
                            label: "Data Fim*",
                            errorMessages: _vm.errors.date_end,
                            show: _vm.dialog
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(_vm.model, "date_end", $event)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "3" } },
                      [
                        _c("emc-calendar-time-picker", {
                          attrs: {
                            error: !!_vm.errors.hour_start,
                            errors: _vm.errors.hour_start,
                            time: _vm.model.hour_start,
                            label: "Hora Início"
                          },
                          on: {
                            "update:time": function($event) {
                              return _vm.$set(_vm.model, "hour_start", $event)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "3" } },
                      [
                        _c("emc-calendar-time-picker", {
                          attrs: {
                            error: !!_vm.errors.hour_end,
                            errors: _vm.errors.hour_end,
                            time: _vm.model.hour_end,
                            label: "Hora Fim"
                          },
                          on: {
                            "update:time": function($event) {
                              return _vm.$set(_vm.model, "hour_end", $event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _vm.renderComponent
                          ? _c("v-switch", {
                              attrs: {
                                disabled: _vm.disableHasCertificate,
                                inset: "",
                                label: "Atestado"
                              },
                              on: {
                                change: function($event) {
                                  return _vm.forceRerender()
                                }
                              },
                              model: {
                                value: _vm.model.has_medical_certificate,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.model,
                                    "has_medical_certificate",
                                    $$v
                                  )
                                },
                                expression: "model.has_medical_certificate"
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _vm.renderComponent && _vm.model.has_medical_certificate
                          ? _c("emc-calendar-date-picker2", {
                              attrs: {
                                model: _vm.model.date_start_certificate,
                                label: "Início*",
                                errorMessages:
                                  _vm.errors.date_start_certificate,
                                show: _vm.dialog,
                                showClear: "",
                                disabled: !_vm.model.has_medical_certificate
                              },
                              on: {
                                "update:model": function($event) {
                                  return _vm.$set(
                                    _vm.model,
                                    "date_start_certificate",
                                    $event
                                  )
                                }
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _vm.renderComponent && _vm.model.has_medical_certificate
                          ? _c("emc-calendar-date-picker2", {
                              attrs: {
                                model: _vm.model.date_end_certificate,
                                label: "Fim*",
                                errorMessages: _vm.errors.date_end_certificate,
                                show: _vm.dialog,
                                showClear: "",
                                disabled: !_vm.model.has_medical_certificate
                              },
                              on: {
                                "update:model": function($event) {
                                  return _vm.$set(
                                    _vm.model,
                                    "date_end_certificate",
                                    $event
                                  )
                                }
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "12" } },
                      [
                        _c("v-textarea", {
                          attrs: {
                            "error-messages": _vm.errors.note,
                            label: "Observação",
                            counter: 255,
                            rules: [
                              function(v) {
                                return (
                                  !v ||
                                  v.length <= 255 ||
                                  "Máximo de 255 caracteres atingido"
                                )
                              }
                            ],
                            outlined: ""
                          },
                          model: {
                            value: _vm.model.note,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "note", $$v)
                            },
                            expression: "model.note"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("emc-alert-snackbar", {
              attrs: {
                show: !!_vm.message.length,
                message: _vm.message,
                color: _vm.error ? "error" : "success"
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }