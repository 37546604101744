var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading
        ? _c("EmcBaseProgressBar")
        : _c("v-simple-table", {
            attrs: { dense: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function() {
                  return [
                    _c("thead", [
                      _c("tr", [
                        _c("th", [_vm._v("Campus")]),
                        _c("th", [_vm._v("Curso")]),
                        _c("th", [_vm._v("Turno")]),
                        _c("th", [_vm._v("Turma")]),
                        _c("th", [_vm._v("Disciplina")]),
                        _c("th", [_vm._v("Carga Horária")]),
                        _c("th", [_vm._v("CH Paga")])
                      ])
                    ]),
                    _c(
                      "tbody",
                      _vm._l(_vm.grids, function(grid) {
                        return _c("tr", { key: grid.id }, [
                          _c("td", [
                            _vm._v(_vm._s(grid.group.campus.abbreviation))
                          ]),
                          _c("td", [_vm._v(_vm._s(grid.group.course.name))]),
                          _c("td", [_vm._v(_vm._s(grid.group.shift.name))]),
                          _c("td", [
                            _vm._v(" " + _vm._s(grid.group.name) + " "),
                            _vm.renderComponent && grid.join
                              ? _c(
                                  "span",
                                  [
                                    _c("emc-info-help", {
                                      attrs: {
                                        icon: "mdi-call-split",
                                        title: "Junção: " + grid.join.join_id
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "text",
                                            fn: function() {
                                              return [
                                                _c(
                                                  "ul",
                                                  { attrs: { type: "none" } },
                                                  _vm._l(
                                                    grid.join.grids,
                                                    function(grid) {
                                                      return _c(
                                                        "li",
                                                        { key: grid.id },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                grid.group
                                                                  .name +
                                                                  " | " +
                                                                  grid
                                                                    .discipline
                                                                    .name
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]),
                          _c("td", [
                            _vm._v(" " + _vm._s(grid.discipline_name) + " "),
                            grid.subGroup
                              ? _c("small", [
                                  _c("br"),
                                  _vm._v(_vm._s(grid.subGroup.name))
                                ])
                              : _vm._e()
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(grid.work_load) +
                                " (" +
                                _vm._s(grid.work_load_type.initials) +
                                ")"
                            )
                          ]),
                          _c("td", { staticClass: "justify-center" }, [
                            _vm._v(
                              _vm._s(
                                grid.work_load_pay
                                  ? grid.work_load_pay
                                  : grid.work_load
                              )
                            )
                          ])
                        ])
                      }),
                      0
                    ),
                    _vm.grids.length > 0
                      ? _c("tfoot", [
                          _c("tr", [
                            _c(
                              "td",
                              {
                                staticClass: "text-right",
                                attrs: { colspan: "6" }
                              },
                              [_vm._v("Total:")]
                            ),
                            _c("td", { staticClass: "justify-center" }, [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(parseFloat(_vm.workLoadPay).toFixed(2))
                                )
                              ])
                            ])
                          ]),
                          !_vm.hideJoins && _vm.gridListJoins.length > 0
                            ? _c("tr", [
                                _c(
                                  "td",
                                  { attrs: { colspan: "7" } },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        attrs: {
                                          "mr-2": "",
                                          "x-small": "",
                                          color: "indigo"
                                        }
                                      },
                                      [_vm._v("mdi-call-split")]
                                    ),
                                    _c(
                                      "strong",
                                      { staticClass: "text-center ma-0" },
                                      [_vm._v("Disciplinas com Junção:")]
                                    ),
                                    _c(
                                      "ul",
                                      { attrs: { type: "none" } },
                                      _vm._l(_vm.gridListJoins, function(
                                        grid,
                                        i
                                      ) {
                                        return _c("li", { key: i }, [
                                          _vm._v(" " + _vm._s(grid) + " ")
                                        ])
                                      }),
                                      0
                                    )
                                  ],
                                  1
                                )
                              ])
                            : _vm._e()
                        ])
                      : _vm._e()
                  ]
                },
                proxy: true
              }
            ])
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }