var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.model,
      title: _vm.title,
      icon: _vm.icon,
      show: _vm.dialog,
      maxWidth: "60%"
    },
    on: {
      onSave: _vm.save,
      "update:model": function($event) {
        _vm.model = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-container",
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "8" } },
                      [
                        _c("sys-select-teacher", {
                          attrs: {
                            model: _vm.model.teacher_id,
                            data: _vm.model.teacher,
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.teacher_id,
                            label: "Professor*",
                            autoComplete: "",
                            autofocus: _vm.model.id == null,
                            dense: ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(_vm.model, "teacher_id", $event)
                            },
                            "update:data": function($event) {
                              return _vm.$set(_vm.model, "teacher", $event)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "2" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            dense: "",
                            error: !!_vm.errors.year,
                            "error-messages": _vm.errors.year,
                            label: "Ano*"
                          },
                          model: {
                            value: _vm.model.year,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "year", $$v)
                            },
                            expression: "model.year"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "2" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            dense: "",
                            error: !!_vm.errors.period,
                            "error-messages": _vm.errors.period,
                            label: "Período*"
                          },
                          model: {
                            value: _vm.model.period,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "period", $$v)
                            },
                            expression: "model.period"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _c("sys-select-campus", {
                          attrs: {
                            model: _vm.model.campus_id,
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.campus_id,
                            label: "Campus",
                            showClear: "",
                            dense: ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(_vm.model, "campus_id", $event)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "2" } },
                      [
                        _c("s-text-field", {
                          attrs: {
                            dense: "",
                            "text-helper":
                              "Carga horária semanal em sala de aula.",
                            "error-messages": _vm.errors.work_load,
                            label: "Carga Horária*"
                          },
                          model: {
                            value: _vm.model.work_load,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "work_load", $$v)
                            },
                            expression: "model.work_load"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "2" } },
                      [
                        _c("s-text-field", {
                          attrs: {
                            dense: "",
                            "text-helper":
                              "Carga horária semanal extra classe ou demais atividades (fora de sala de aula).",
                            "error-messages": _vm.errors.activity,
                            label: "Atividade*"
                          },
                          model: {
                            value: _vm.model.activity,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "activity", $$v)
                            },
                            expression: "model.activity"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "2" } },
                      [
                        _c("s-text-field", {
                          attrs: {
                            dense: "",
                            "text-helper":
                              "Carga horária semanal extra classe ou demais atividades (fora de sala de aula) que deve computar como carga horária de salário.",
                            "error-messages": _vm.errors.activity_work_load,
                            label: "Atividade (CH Salário)*"
                          },
                          model: {
                            value: _vm.model.activity_work_load,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "activity_work_load", $$v)
                            },
                            expression: "model.activity_work_load"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "2" } },
                      [
                        _c("s-text-field", {
                          attrs: {
                            dense: "",
                            "text-helper":
                              "Carga horária semanal disponibilizada pelo professor no período.",
                            "error-messages": _vm.errors.availability,
                            label: "Disponibilidade*"
                          },
                          model: {
                            value: _vm.model.availability,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "availability", $$v)
                            },
                            expression: "model.availability"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "12" } },
                      [
                        _c("v-textarea", {
                          attrs: {
                            outlined: "",
                            name: "input-7-4",
                            label: "Anotações",
                            value: _vm.model.note,
                            error: _vm.errors.length > 0,
                            "error-messages": _vm.errors.note,
                            rows: "2",
                            "row-height": "20",
                            counter: 255,
                            rules: [
                              function(v) {
                                return (
                                  !v ||
                                  v.length <= 255 ||
                                  "Máximo de 255 caracteres atingido"
                                )
                              }
                            ]
                          },
                          model: {
                            value: _vm.model.note,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "note", $$v)
                            },
                            expression: "model.note"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("emc-alert-snackbar", {
              attrs: {
                show: !!_vm.message.length,
                message: _vm.message,
                color: _vm.error ? "error" : "success"
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }