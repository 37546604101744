var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    { attrs: { cols: "12" } },
    [
      _c("EmcCardStats", {
        attrs: {
          color: _vm.data.color,
          icon: _vm.data.icon,
          "icon-large": "",
          title: _vm.data.title,
          value: _vm.data.value,
          "sub-text": _vm.data.description
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  {
                                    attrs: {
                                      loading: _vm.loading,
                                      color: "primary",
                                      icon: "",
                                      disabled: !_vm.data.value
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.$emit(
                                          "filterChart",
                                          _vm.data.title
                                        )
                                      }
                                    }
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v("mdi-filter-outline")
                                ])
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  },
                  [_c("span", [_vm._v("Filtrar")])]
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }