var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("sys-register-base", {
        attrs: {
          model: {},
          title: "Professores",
          icon: "mdi-mailbox",
          show: _vm.dialog,
          maxWidth: "65%",
          showRequiredFields: false,
          showButton: false
        },
        on: {
          "update:show": function($event) {
            _vm.dialog = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "tools",
            fn: function() {
              return [
                _c("emc-button-icon", {
                  attrs: {
                    disabled: _vm.data == null,
                    icon: "mdi-google-maps",
                    text: "Ver no mapa",
                    color: "purple",
                    href: _vm.source
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "form",
            fn: function() {
              return [
                _c(
                  "v-container",
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "12" } },
                          [
                            _vm.loading
                              ? _c("v-progress-linear", {
                                  attrs: {
                                    indeterminate: "",
                                    color: "yellow darken-2"
                                  }
                                })
                              : _vm._e(),
                            _c("v-simple-table", {
                              attrs: {
                                "fixed-header": "",
                                height: "300",
                                dense: ""
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function() {
                                    return [
                                      _c(
                                        "caption",
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: { cols: "12", sm: "4" }
                                                },
                                                [
                                                  _c("s-switch", {
                                                    staticClass: "ml-5",
                                                    attrs: {
                                                      "text-helper":
                                                        "Exibir apenas professores que informaram possuir aderência pela disciplina",
                                                      label: "Aderência",
                                                      loading:
                                                        _vm.loadingDiscipline,
                                                      inset: ""
                                                    },
                                                    on: {
                                                      input: function($event) {
                                                        _vm.loadingDiscipline = true
                                                        _vm.loadData()
                                                      }
                                                    },
                                                    model: {
                                                      value: _vm.hasDiscipline,
                                                      callback: function($$v) {
                                                        _vm.hasDiscipline = $$v
                                                      },
                                                      expression:
                                                        "hasDiscipline"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: { cols: "12", sm: "4" }
                                                },
                                                [
                                                  _c("s-switch", {
                                                    attrs: {
                                                      "text-helper":
                                                        "Exibir apenas professores com disponibilidade para lecionar a disciplina",
                                                      label: "Disponível",
                                                      loading:
                                                        _vm.loadingAvailability,
                                                      inset: ""
                                                    },
                                                    on: {
                                                      input: function($event) {
                                                        _vm.loadingAvailability = true
                                                        _vm.loadData()
                                                      }
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.hasAvailability,
                                                      callback: function($$v) {
                                                        _vm.hasAvailability = $$v
                                                      },
                                                      expression:
                                                        "hasAvailability"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: { cols: "12", sm: "4" }
                                                },
                                                [
                                                  _c("v-select", {
                                                    staticClass: "mr-3",
                                                    attrs: {
                                                      items: _vm.distances,
                                                      label: "Distância",
                                                      "return-object": ""
                                                    },
                                                    on: {
                                                      change: function($event) {
                                                        return _vm.loadData()
                                                      }
                                                    },
                                                    model: {
                                                      value: _vm.distance,
                                                      callback: function($$v) {
                                                        _vm.distance = $$v
                                                      },
                                                      expression: "distance"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            [
                                              _c("v-col", [
                                                _c("strong", [
                                                  _vm._v("Unidade: ")
                                                ]),
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.data
                                                      ? _vm.data.campus.name
                                                      : null
                                                  ) + " "
                                                ),
                                                _c("br"),
                                                _c("small", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.data
                                                        ? _vm.data.address
                                                        : null
                                                    )
                                                  )
                                                ])
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("thead", [
                                        _c("tr", [
                                          _c("th", [_vm._v("Professor")]),
                                          _c("th", [_vm._v("Distância")]),
                                          _c("th", [_vm._v("Duração")]),
                                          _c(
                                            "th",
                                            {
                                              staticStyle: {
                                                "text-align": "center"
                                              }
                                            },
                                            [_vm._v("Aderência")]
                                          ),
                                          _c(
                                            "th",
                                            {
                                              staticStyle: {
                                                "text-align": "center"
                                              }
                                            },
                                            [_vm._v("Disponível")]
                                          )
                                        ])
                                      ]),
                                      _vm.data
                                        ? _c(
                                            "tbody",
                                            _vm._l(_vm.data.items, function(
                                              item,
                                              index
                                            ) {
                                              return _c("tr", { key: index }, [
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(item.teacher.name)
                                                  )
                                                ]),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(item.distance.text)
                                                  )
                                                ]),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(item.duration.text)
                                                  )
                                                ]),
                                                _c(
                                                  "td",
                                                  {
                                                    attrs: {
                                                      align: "center",
                                                      valign: "center"
                                                    }
                                                  },
                                                  [
                                                    _vm.hasAttribution(
                                                      item.teacher
                                                    )
                                                      ? _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              small: "",
                                                              color: "success"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "mdi-check-circle-outline"
                                                            )
                                                          ]
                                                        )
                                                      : _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              small: "",
                                                              color: "error"
                                                            }
                                                          },
                                                          [_vm._v("mdi-cancel")]
                                                        )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "td",
                                                  {
                                                    attrs: {
                                                      align: "center",
                                                      valign: "center"
                                                    }
                                                  },
                                                  [
                                                    item.teacher
                                                      .hasAvailability &&
                                                    !item.teacher.hasTimeTable
                                                      ? _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              small: "",
                                                              color: "success"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "mdi-check-circle-outline"
                                                            )
                                                          ]
                                                        )
                                                      : item.teacher
                                                          .hasAvailability &&
                                                        item.teacher
                                                          .hasTimeTable
                                                      ? _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              small: "",
                                                              color: "amber"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "mdi-alert-outline"
                                                            )
                                                          ]
                                                        )
                                                      : _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              small: "",
                                                              color: "error"
                                                            }
                                                          },
                                                          [_vm._v("mdi-cancel")]
                                                        )
                                                  ],
                                                  1
                                                )
                                              ])
                                            }),
                                            0
                                          )
                                        : _vm._e()
                                    ]
                                  },
                                  proxy: true
                                }
                              ])
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }