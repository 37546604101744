var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.model,
      title: _vm.title,
      icon: _vm.icon,
      show: _vm.dialog,
      maxWidth: "40%"
    },
    on: {
      onSave: _vm.save,
      "update:model": function($event) {
        _vm.model = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-container",
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _c("sys-select-payment-type", {
                          attrs: {
                            model: _vm.model.payment_type_id,
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.payment_type_id,
                            label: "Tipo de Pagamento*",
                            dense: "",
                            disabled:
                              _vm.model.id &&
                              _vm.model.payment_export_status_id != 1
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(
                                _vm.model,
                                "payment_type_id",
                                $event
                              )
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "8" } },
                      [
                        _c("sys-select-company", {
                          attrs: {
                            model: _vm.model.companies,
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.companies,
                            label: "Empresa",
                            multiple: "",
                            dense: "",
                            disabled:
                              _vm.model.id &&
                              _vm.model.payment_export_status_id != 1
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(_vm.model, "companies", $event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("s-switch", {
                          attrs: {
                            "text-helper":
                              "Gerar apenas os registros adicionais, que não foram processados nos arquivos anteriores",
                            inset: "",
                            error: !!_vm.errors.complementary,
                            "error-messages": _vm.errors.complementary,
                            label: "Complementar",
                            disabled: _vm.disabledFields
                          },
                          model: {
                            value: _vm.model.complementary,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "complementary", $$v)
                            },
                            expression: "model.complementary"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "3" } },
                      [
                        _vm.model && _vm.model.payment_type_id == 1
                          ? _c("sys-select-payment-export-prorated-type", {
                              attrs: {
                                model:
                                  _vm.model.payment_export_prorated_type_id,
                                error: _vm.errors.length > 0,
                                errors:
                                  _vm.errors.payment_export_prorated_type_id,
                                label: "Rateio*",
                                disabled: _vm.disabledFields,
                                dense: ""
                              },
                              on: {
                                "update:model": function($event) {
                                  return _vm.$set(
                                    _vm.model,
                                    "payment_export_prorated_type_id",
                                    $event
                                  )
                                }
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "3" } },
                      [
                        _c("v-select", {
                          attrs: {
                            items: _vm.monthsFiltered,
                            "item-value": "id",
                            "item-text": "name",
                            error: !!_vm.errors.month,
                            "error-messages": _vm.errors.month,
                            label: "Mês",
                            required: "",
                            disabled: _vm.disabledFields,
                            dense: ""
                          },
                          model: {
                            value: _vm.model.month,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "month", $$v)
                            },
                            expression: "model.month"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.model.complementary
                  ? _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "12" } },
                          [
                            _c("sys-select-teacher-combo", {
                              attrs: {
                                model: _vm.model.teachers,
                                error: _vm.errors.length > 0,
                                errors: _vm.errors.teachers,
                                label: "Professores",
                                dense: ""
                              },
                              on: {
                                "update:model": function($event) {
                                  return _vm.$set(_vm.model, "teachers", $event)
                                }
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "12" } },
                      [
                        _c("v-textarea", {
                          attrs: {
                            error: !!_vm.errors.note,
                            "error-messages": _vm.errors.note,
                            label: "Observação",
                            counter: 255,
                            rules: [
                              function(v) {
                                return (
                                  !v ||
                                  v.length <= 255 ||
                                  "Máximo de 255 caracteres atingido"
                                )
                              }
                            ],
                            outlined: "",
                            disabled:
                              _vm.model.id &&
                              _vm.model.payment_export_status_id == 4
                          },
                          model: {
                            value: _vm.model.note,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "note", $$v)
                            },
                            expression: "model.note"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("emc-alert-snackbar", {
              attrs: {
                show: !!_vm.message.length,
                message: _vm.message,
                color: _vm.error ? "error" : "success"
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }